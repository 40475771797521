import React, {useState, useEffect} from 'react';
import {Table, Button, Input, Modal, message, Card, Avatar} from 'antd';
import {SendOutlined, CommentOutlined} from '@ant-design/icons';

function NewUseCase() {
    const [bots, setBots] = useState([]);
    const [selectedBotId, setSelectedBotId] = useState(null);
    const [chatId, setChatId] = useState(null);
    const [query, setQuery] = useState('');
    const [response, setResponse] = useState('');
    const [responseData, setResponseData] = useState([]); // For json_data
    const [isLoading, setIsLoading] = useState(false);
    const [isChatModalOpen, setIsChatModalOpen] = useState(false);

    const accessToken = localStorage.getItem('accessToken'); // Retrieve the access token

    const baseApiUrl = 'https://api.finimaps.com';

    // Fetch bots on component mount
    useEffect(() => {
        const fetchBots = async () => {
            try {
                const response = await fetch(`${baseApiUrl}/all-bots/`, {
                    method: 'GET',
                    headers: {
                        Authorization: `Bearer ${accessToken}`,
                    },
                });

                if (response.ok) {
                    const botsData = await response.json();
                    setBots(
                        botsData.map(bot => ({
                            ...bot,
                            icon_full_path: bot.icon_path ? `https://api.finimaps.com/${bot.icon_path}` : null,
                        }))
                    );
                } else {
                    const errorData = await response.json();
                    console.error('Failed to fetch bots:', errorData.detail || 'Unknown error');
                }
            } catch (error) {
                console.error('Error fetching bots:', error);
            }
        };

        fetchBots();
    }, [accessToken]);

    const handleChatCreation = async (botId) => {
        try {
            const response = await fetch(
                `${baseApiUrl}/agent/${botId}/new_chat/`,
                {
                    method: 'GET',
                    headers: {
                        Authorization: `Bearer ${accessToken}`,
                    },
                }
            );

            if (response.ok) {
                const {chat_id} = await response.json();
                setSelectedBotId(botId);
                setChatId(chat_id);
                setResponse('');
                setResponseData([]);
                setIsChatModalOpen(true);
                message.success('New chat created successfully.');
            } else {
                const errorData = await response.json();
                console.error('Failed to create chat:', errorData.detail || 'Unknown error');
                message.error('Failed to create chat.');
            }
        } catch (error) {
            console.error('Error creating chat:', error);
            message.error('Error creating chat.');
        }
    };

    const handleQuerySend = async () => {
        if (!query.trim()) {
            message.warning('Please enter a query before sending.');
            return;
        }

        setIsLoading(true);

        try {
            const response = await fetch(`${baseApiUrl}/agent/response`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${accessToken}`,
                },
                body: JSON.stringify({
                    bot_id: selectedBotId,
                    chat_id: chatId,
                    query,
                }),
            });

            if (response.ok) {
                const data = await response.json();
                setResponse(data.json_data[0]);
                setResponseData(data.json_data); // Save the full json_data for card rendering
                message.success('Query response received successfully.');
            } else {
                const errorData = await response.json();
                console.error('Failed to send query:', errorData.detail || 'Unknown error');
                message.error('Failed to send query.');
            }
        } catch (error) {
            console.error('Error sending query:', error);
            message.error('Error sending query.');
        } finally {
            setIsLoading(false);
        }
    };

    const columns = [
        {
            title: 'Icon',
            dataIndex: 'icon_full_path',
            key: 'icon_full_path',
            render: (icon) =>
                icon ? (
                    <Avatar src={icon} shape="square" size={40}/>
                ) : (
                    <Avatar shape="square" size={40}>
                        N/A
                    </Avatar>
                ),
        },
        {
            title: 'Mall',
            dataIndex: 'mall_name',
            key: 'mall_name',
        },
        {
            title: 'Location',
            dataIndex: 'mall_location',
            key: 'mall_location',
        },
        {
            title: 'Bot Name',
            dataIndex: 'bot_name',
            key: 'bot_name',
        },
        {
            title: 'Description',
            dataIndex: 'description',
            key: 'description',
        },
        {
            title: 'Action',
            key: 'action',
            render: (_, record) => (
                <Button
                    type="primary"
                    icon={<CommentOutlined/>}
                    onClick={() => handleChatCreation(record.bot_id)}
                >
                    Chat
                </Button>
            ),
        },
    ];

    return (
        <div
            style={{
                padding: '20px',
                display: 'flex',
                flexDirection: 'column',
                gap: '20px',
            }}
        >
            <Card
                title="Bots Management"
                bordered={false}
                style={{
                    boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
                    borderRadius: '8px',
                }}
            >
                <Table
                    columns={columns}
                    dataSource={bots}
                    rowKey={(record) => record.bot_id}
                    pagination={{pageSize: 5}}
                />
            </Card>

            <Modal
                title="Chat with Bot"
                open={isChatModalOpen}
                onCancel={() => setIsChatModalOpen(false)}
                footer={null}
                centered
                style={{borderRadius: '8px'}}
            >
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '20px',
                    }}
                >
                    <div
                        style={{
                            display: 'flex',
                            gap: '10px',
                            alignItems: 'center',
                        }}
                    >
                        <Input
                            placeholder="Type your query here..."
                            value={query}
                            onChange={(e) => setQuery(e.target.value)}
                            disabled={isLoading}
                        />
                        <Button
                            type="primary"
                            icon={<SendOutlined/>}
                            onClick={handleQuerySend}
                            loading={isLoading}
                        >
                            Send
                        </Button>
                    </div>
                    {response && (
                        <div
                            style={{
                                padding: '10px',
                                backgroundColor: '#f6f6f6',
                                borderRadius: '5px',
                                boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
                            }}
                        >
                            <h3>Response:</h3>
                            <p>
                                <strong>Name:</strong> {response.name}
                            </p>
                            <p>
                                <strong>Short Description:</strong> {response.short_description}
                            </p>
                            <p>
                                <strong>Open/Close Info:</strong> {response.open_close_info}
                            </p>
                            <a
                                href={response.media_link}
                                target="_blank"
                                rel="noopener noreferrer"
                                style={{color: '#1890ff'}}
                            >
                                View Media
                            </a>
                        </div>
                    )}
                    {responseData.length > 0 && (
                        <div
                            style={{
                                display: 'flex',
                                flexWrap: 'wrap',
                                gap: '20px',
                                marginTop: '20px',
                            }}
                        >
                            {responseData.map((item, index) => (
                                <Card
                                    key={index}
                                    title={item.name}
                                    style={{
                                        width: '300px',
                                        boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
                                        borderRadius: '8px',
                                    }}
                                >
                                    <p>
                                        <strong>Short Description:</strong> {item.short_description}
                                    </p>
                                    <p>
                                        <strong>Open/Close Info:</strong> {item.open_close_info}
                                    </p>
                                    <a
                                        href={item.media_link}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        style={{color: '#1890ff'}}
                                    >
                                        View Media
                                    </a>
                                </Card>
                            ))}
                        </div>
                    )}
                </div>
            </Modal>
        </div>
    );
}

export default NewUseCase;
