import React, {useState, useEffect, useRef} from 'react';
import './EventManagement.css';
import {MdClose} from 'react-icons/md';

const EventManagement = () => {
    const [Events, setEvents] = useState([]);
    const [formData, setFormData] = useState({});
    const [notification, setNotification] = useState({message: '', type: ''});
    const [file, setFile] = useState(null);
    const [selectedEvent, setSelectedEvent] = useState(null);
    const [isAddModalOpen, setIsAddModalOpen] = useState(false);
    const [isEditModalOpen, setIsEditModalOpen] = useState(false);
    const [isImportModalOpen, setIsImportModalOpen] = useState(false);
    const [isViewModalOpen, setIsViewModalOpen] = useState(false);
    const modalRef = useRef();
    const [customers, setCustomers] = useState([]);
    const [selectedCustomerId, setSelectedCustomerId] = useState("");
    const [floors, setFloors] = useState([]);
    const [malls, setMalls] = useState([]);
    const [selectedMallId, setSelectedMallId] = useState("");
    const [selectedStoreId, setselectedStoreId] = useState("");
    const [floorMap, setFloorMap] = useState({});
    const [mallMap, setMallMap] = useState({});
    const [Stores, setStores] = useState([]);


    // Fetch customers on component mount
    useEffect(() => {
        fetchStores();
    }, []);

    // const fetchCustomers = async () => {
    //     try {
    //         const response = await fetch("https://api.finimaps.com/customers/");
    //         const data = await response.json();
    //         setCustomers(data);
    //     } catch (error) {
    //         console.error("Failed to fetch customers", error);
    //     }
    // };

    // Helper function to get customer name by ID
    const getFloorName = (customerId) => {
        const store = Stores.find(c => c._id === customerId);
        return store ? store.name : 'Unknown Floor';
    };
    const [searchTerm, setSearchTerm] = useState("");

    const handleSearchChange = (e) => {
        setSearchTerm(e.target.value.toLowerCase());
    };

    const filteredEvents = Events.filter(event =>
        event.title.toLowerCase().includes(searchTerm) ||
        event.description.toLowerCase().includes(searchTerm) ||
        getFloorName(event.store_id).toLowerCase().includes(searchTerm)
    );


    // Fetch all malls
    const fetchMalls = async () => {
        try {
            const response = await fetch('https://api.finimaps.com/malls/');
            if (response.ok) {
                const data = await response.json();
                setMalls(data);
            } else {
                throw new Error('Failed to load malls');
            }
        } catch (error) {
            setNotification({message: error.message, type: 'error'});
        }
    };

    // Fetch all Stores
    const fetchStores = async () => {
        try {
            const response = await fetch('https://api.finimaps.com/stores/');
            if (response.ok) {
                const data = await response.json();
                setStores(data);
            } else {
                throw new Error('Failed to load Stores');
            }
        } catch (error) {
            setNotification({message: error.message, type: 'error'});
        }
    };


    // Fetch all Events
    const fetchEvents = async () => {
        try {
            const response = await fetch('https://api.finimaps.com/events/');
            if (response.ok) {
                const data = await response.json();
                setEvents(data);
            } else {
                throw new Error('Failed to load Events');
            }
        } catch (error) {
            setNotification({message: error.message, type: 'error'});
        }
    };

    // Add a new Event
    const addEvent = async () => {
        try {
            const response = await fetch("https://api.finimaps.com/events/", {
                method: "POST",
                headers: {"Content-Type": "application/json"},
                body: JSON.stringify({
                    store_id: selectedStoreId,  // Floor ID from the selected floor dropdown
                    event_type: formData.event_type,
                    title: formData.title,
                    description: formData.description,
                    start_time: formData.start_time,
                    end_time: formData.end_time,  // Ensure latitude is a number
                }),
            });
            if (response.ok) {
                alert("Event added successfully");
                setFormData({});
                setSelectedMallId("");
                setselectedStoreId("");
                setIsAddModalOpen(false);
                fetchEvents();
            } else {
                throw new Error("Failed to add Event");
            }
        } catch (error) {
            alert(error.message, "error");
        }
    };


    const openEditModal = (Event) => {
        setSelectedEvent(Event);
        setFormData({
            event_type: Event.event_type,
            title: Event.title,
            description: Event.description,
            start_time: Event.start_time,
            end_time: Event.end_time,
        });
        setIsEditModalOpen(true);
    };
    // Update a Event by ID
    // Update a Event by ID
    // Update an Event by ID
    const updateEvent = async () => {
        try {
            if (!selectedEvent) return;

            const response = await fetch(`https://api.finimaps.com/events/${selectedEvent._id}`, {
                method: "PUT",
                headers: {"Content-Type": "application/json"},
                body: JSON.stringify({
                    event_type: formData.event_type,
                    title: formData.title,
                    description: formData.description,
                    start_time: formData.start_time,
                    end_time: formData.end_time,
                }),
            });
            if (response.ok) {
                alert("Event updated successfully");
                setIsEditModalOpen(false);
                fetchEvents();  // Refresh events list after updating
            } else {
                throw new Error("Failed to update Event");
            }
        } catch (error) {
            alert(error.message);
        }
    };


    // Delete a Event by ID
    const deleteEvent = async (id) => {
        const confirmed = window.confirm("Are you sure you want to delete this Event?");
        if (!confirmed) return;

        try {
            const response = await fetch(`https://api.finimaps.com/events/${id}`, {method: 'DELETE'});
            if (response.ok) {
                alert('Event deleted successfully');
                setEvents(Events.filter(Event => Event._id !== id));
            } else {
                throw new Error('Failed to delete Event');
            }
        } catch (error) {
            alert(error.message);
        }
    };

    // Export Events to CSV
    const exportEvents = async () => {
        try {
            const response = await fetch('https://api.finimaps.com/events/export/');
            if (response.ok) {
                const blob = await response.blob();
                const url = URL.createObjectURL(blob);
                const link = document.createElement('a');
                link.href = url;
                link.download = 'Events.csv';
                link.click();
                alert('Events exported successfully');
            } else {
                throw new Error('Failed to export Events');
            }
        } catch (error) {
            alert(error.message);
        }
    };

    // Import Events from CSV
    const importEvents = async () => {
        const formData = new FormData();
        formData.append('file', file);
        formData.append('store_id', selectedStoreId);
        try {
            const response = await fetch('https://api.finimaps.com/events/import/', {
                method: 'POST',
                body: formData,
            });
            if (response.ok) {
                alert('Events imported successfully');
                setIsImportModalOpen(false);
                fetchEvents();
            } else {
                throw new Error('Failed to import Events');
            }
        } catch (error) {
            alert(error.message);
        }
    };

    // Fetch Event details for viewing
    const viewEventDetail = async (id) => {
        try {
            const response = await fetch(`https://api.finimaps.com/events/${id}/detail/`);
            if (response.ok) {
                const data = await response.json();
                setSelectedEvent(data);
                setIsViewModalOpen(true);
            } else {
                throw new Error('Failed to fetch Event details');
            }
        } catch (error) {
            setNotification({message: error.message, type: 'error'});
        }
    };

    // Close modals when clicking outside
    const closeModal = (e, setModalState) => {
        if (modalRef.current && !modalRef.current.contains(e.target)) {
            setModalState(false);
        }
    };

    // Fetch Events on component mount
    useEffect(() => {
        fetchEvents();
        document.addEventListener("mousedown", (e) => closeModal(e, setIsAddModalOpen));
        document.addEventListener("mousedown", (e) => closeModal(e, setIsEditModalOpen));
        document.addEventListener("mousedown", (e) => closeModal(e, setIsImportModalOpen));
        document.addEventListener("mousedown", (e) => closeModal(e, setIsViewModalOpen));
        return () => {
            document.removeEventListener("mousedown", closeModal);
        };
    }, []);

    return (
        <div className="Event-container">
            <h1 className="Event-title">Event Management</h1>
            <div className="event-search-container">
                <input
                    type="text"
                    placeholder="Search by Event Name, Description, or Store Name..."
                    className="event-search-input"
                    value={searchTerm}
                    onChange={handleSearchChange}
                />
            </div>

            {/* Button container */}
            <div className="Event-button-container">
                <button className="Event-action-btn Event-add-btn" onClick={() => setIsAddModalOpen(true)}>Create New
                    Event
                </button>
                <button className="Event-action-btn Event-import-btn" onClick={() => setIsImportModalOpen(true)}>Import
                    Events
                </button>
                <button className="Event-action-btn Event-export-btn" onClick={exportEvents}>Export Events</button>
            </div>

            {/* Event Table */}
            <div className="Event-table-container">
                <table className="Event-table">
                    <thead>
                    <tr>
                        <th>Store name</th>
                        <th>Event Type</th>
                        <th>Name</th>
                        <th>Description</th>
                        <th>Start Time</th>
                        <th>End Time</th>
                        <th>Actions</th>
                    </tr>
                    </thead>
                    <tbody>
                    {filteredEvents.map((Event) => (
                        <tr key={Event._id}>
                            <td>{getFloorName(Event.store_id)}</td>
                            <td>{Event.event_type}</td>
                            <td>{Event.title}</td>
                            <td>{Event.description}</td>
                            <td>{Event.start_time}</td>
                            <td>{Event.end_time}</td>
                            <td>
                                <button className="Event-action-btn Event-view-btn"
                                        onClick={() => viewEventDetail(Event._id)}>
                                    View Media
                                </button>
                                <button className="Event-action-btn Event-edit-btn"
                                        onClick={() => openEditModal(Event)}>
                                    Edit
                                </button>
                                <button className="Event-action-btn Event-delete-btn"
                                        onClick={() => deleteEvent(Event._id)}>
                                    Delete
                                </button>
                            </td>
                        </tr>
                    ))}
                    </tbody>

                </table>
            </div>

            {/* Add, Edit, Import, and View Modals */}
            {/* Implement similar to CustomerManagement with the updated Event-specific classes */}
            {/* Add Event Modal */}
            {isAddModalOpen && (
                <div className="Event-modal-overlay" onClick={(e) => closeModal(e, setIsAddModalOpen)}>
                    <div className="Event-modal-content" ref={modalRef}>
                        <button className="Event-close-btn" onClick={() => setIsAddModalOpen(false)}>
                            <MdClose/>
                        </button>
                        <h2 className="Event-modal-title">Add New Event</h2>
                        <form
                            onSubmit={(e) => {
                                e.preventDefault();
                                addEvent();
                            }}
                            className="Event-form-container"
                        >
                            <select
                                value={selectedStoreId}
                                onChange={(e) => setselectedStoreId(e.target.value)}
                                required
                                className="mall-form-select"
                            >
                                <option value="">Select Store</option>
                                {Stores.map((store) => (
                                    <option key={store._id} value={store._id}>
                                        {store.name}
                                    </option>
                                ))}
                            </select>

                            {/* Event Name */}
                            <input
                                type="text"
                                name="event_type"
                                placeholder="Event Type"
                                required
                                onChange={(e) => setFormData({...formData, event_type: e.target.value})}
                            />

                            {/* Description */}
                            <input
                                type="text"
                                name="title"
                                placeholder="Name"
                                required
                                onChange={(e) => setFormData({...formData, title: e.target.value})}
                            />

                            {/* Open Close Info */}
                            <input
                                type="text"  // Change this to "text" as it's a string field, not a number
                                name="description"
                                placeholder="Description"
                                required
                                onChange={(e) => setFormData({...formData, description: e.target.value})}
                            />

                            {/* Address */}
                            <input
                                type="text"  // Change this to "text" as it's a string field
                                name="address"
                                placeholder="Address"
                                required
                                onChange={(e) => setFormData({...formData, address: e.target.value})}
                            />

                            {/* Latitude */}
                            <input
                                type="date"
                                step="any"  // Allows decimal input
                                name="start_time"
                                placeholder="Start Time"
                                required
                                onChange={(e) => setFormData({...formData, start_time: e.target.value})}
                            />

                            {/* Longitude */}
                            <input
                                type="date"
                                step="any"  // Allows decimal input
                                name="end_time"
                                placeholder="End Time"
                                required
                                onChange={(e) => setFormData({...formData, end_time: e.target.value})}
                            />


                            <button type="submit" className="Event-submit-btn">Add Event</button>
                        </form>
                    </div>
                </div>
            )}

            {/* Edit Event Modal */}
            {isEditModalOpen && selectedEvent && (
                <div className="Event-modal-overlay" onClick={(e) => closeModal(e, setIsEditModalOpen)}>
                    <div className="Event-modal-content" ref={modalRef}>
                        <button className="Event-close-btn" onClick={() => setIsEditModalOpen(false)}>
                            <MdClose/>
                        </button>
                        <h2 className="Event-modal-title">Edit Event</h2>
                        <form
                            onSubmit={(e) => {
                                e.preventDefault();
                                updateEvent();
                            }}
                            className="Event-form-container"
                        >
                            {/* Event Name */}
                            <input
                                type="text"
                                name="event_type"
                                value={formData.event_type || ""}
                                placeholder="Event Type"
                                required
                                onChange={(e) => setFormData({...formData, event_type: e.target.value})}
                            />

                            {/* Description */}
                            <input
                                type="text"
                                name="title"
                                value={formData.title || ''}
                                placeholder="Name"
                                required
                                onChange={(e) => setFormData({...formData, title: e.target.value})}
                            />

                            {/* Open Close Info */}
                            <input
                                type="text"  // Change this to "text" as it's a string field, not a number
                                name="description"
                                value={formData.description || ''}
                                placeholder="Description"
                                required
                                onChange={(e) => setFormData({...formData, description: e.target.value})}
                            />

                            {/* Address */}
                            <input
                                type="date"  // Change this to "text" as it's a string field
                                name="start_time"
                                value={formData.start_time || ''}
                                placeholder="Start Time"
                                required
                                onChange={(e) => setFormData({...formData, start_time: e.target.value})}
                            />

                            {/* Latitude */}
                            <input
                                type="date"
                                name="end_time"
                                value={formData.end_time || ''}
                                placeholder="End Time"
                                required
                                onChange={(e) => setFormData({...formData, end_time: e.target.value})}
                            />
                            <button type="submit" className="Event-submit-btn">Update Event</button>
                        </form>
                    </div>
                </div>
            )}

            {/* Import Event Modal */}
            {isImportModalOpen && (
                <div className="Event-import-modal-overlay" onClick={(e) => closeModal(e, setIsImportModalOpen)}>
                    <div className="Event-import-modal-content" ref={modalRef}>
                        <button className="Event-close-btn" onClick={() => setIsImportModalOpen(false)}><MdClose/>
                        </button>
                        <h2 className="Event-import-modal-title">Import Events</h2>
                        <select
                            value={selectedStoreId}
                            onChange={(e) => setselectedStoreId(e.target.value)}
                            required
                            className="mall-form-select"
                        >
                            <option value="">Select Store</option>
                            {Stores.map((store) => (
                                <option key={store._id} value={store._id}>
                                    {store.name}
                                </option>
                            ))}
                        </select>
                        <input type="file" className="Event-import-file-input"
                               onChange={(e) => setFile(e.target.files[0])}/>
                        <button onClick={importEvents} className="Event-import-action-btn">Import</button>
                    </div>
                </div>
            )}

            {/* View Event Modal */}
            {isViewModalOpen && selectedEvent && (
                <div className="Event-view-modal-overlay" onClick={(e) => closeModal(e, setIsViewModalOpen)}>
                    <div className="Event-view-modal-content" ref={modalRef}>
                        <button className="Event-close-btn" onClick={() => setIsViewModalOpen(false)}><MdClose/>
                        </button>
                        {/*<h2 className="Event-view-modal-title">Event Details</h2>*/}
                        {/*<div className="Event-view-details-container">*/}
                        {/*    <div className="Event-view-detail-row">*/}
                        {/*        <span className="Event-view-detail-label">Name</span>*/}
                        {/*        <span className="Event-view-detail-data">{selectedEvent.name}</span>*/}
                        {/*    </div>*/}
                        {/*    <div className="Event-view-detail-row">*/}
                        {/*        <span className="Event-view-detail-label">Location</span>*/}
                        {/*        <span className="Event-view-detail-data">{selectedEvent.location}</span>*/}
                        {/*    </div>*/}
                        {/*    <div className="Event-view-detail-row">*/}
                        {/*        <span className="Event-view-detail-label">Total Area (sqft)</span>*/}
                        {/*        <span className="Event-view-detail-data">{selectedEvent.total_area_sqft}</span>*/}
                        {/*    </div>*/}
                        {/*    <div className="Event-view-detail-row">*/}
                        {/*        <span className="Event-view-detail-label">Parking Capacity</span>*/}
                        {/*        <span className="Event-view-detail-data">{selectedEvent.parking_capacity}</span>*/}
                        {/*    </div>*/}
                        {/*    <div className="Event-view-detail-row">*/}
                        {/*        <span className="Event-view-detail-label">Opening Date</span>*/}
                        {/*        <span className="Event-view-detail-data">{selectedEvent.opening_date}</span>*/}
                        {/*    </div>*/}
                        {/*</div>*/}
                        {/* Events Details Table */}
                        <div className="Events-floor-container">
                            <h3 className="Events-floor-view-modal-subtitle">Events</h3>
                            {selectedEvent.events && selectedEvent.events.length > 0 ? (
                                <table className="floor-Events-table">
                                    <thead>
                                    <tr>
                                        <th>Type</th>
                                        <th>Name</th>
                                        <th>Description</th>
                                        <th>Start Time</th>
                                        <th>End Time</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {selectedEvent.events.map((event) => (
                                        <tr key={event._id}>
                                            <td>{event.event_type}</td>
                                            <td>{event.title}</td>
                                            <td>{event.description}</td>
                                            <td>{event.start_time}</td>
                                            <td>{event.end_time}</td>
                                        </tr>
                                    ))}
                                    </tbody>
                                </table>
                            ) : (
                                <p className="no-Events-message">No Events yet</p>
                            )}
                        </div>
                    </div>
                </div>
            )}

        </div>
    );
};

export default EventManagement;
