import React, {useState, useEffect, useRef} from 'react';
import './ChatbotManagement.css';
import {MdClose} from 'react-icons/md';

const ChatbotManagement = () => {
    const [Chatbots, setChatbots] = useState([]);
    const [formData, setFormData] = useState({});
    const [notification, setNotification] = useState({message: '', type: ''});
    const [file, setFile] = useState(null);
    const [selectedChatbot, setSelectedChatbot] = useState(null);
    const [isAddModalOpen, setIsAddModalOpen] = useState(false);
    const [isEditModalOpen, setIsEditModalOpen] = useState(false);
    const [isImportModalOpen, setIsImportModalOpen] = useState(false);
    const [isViewModalOpen, setIsViewModalOpen] = useState(false);
    const modalRef = useRef();
    const [customers, setCustomers] = useState([]);
    const [selectedCustomerId, setSelectedCustomerId] = useState("");
    const [floors, setFloors] = useState([]);
    const [malls, setMalls] = useState([]);
    const [selectedMallId, setSelectedMallId] = useState("");
    const [selectedStoreId, setselectedStoreId] = useState("");
    const [floorMap, setFloorMap] = useState({});
    const [mallMap, setMallMap] = useState({});
    const [Stores, setStores] = useState([]);
    const [loading, setLoading] = useState(false);


    // Fetch customers on component mount
    useEffect(() => {
        fetchChatbots();
        fetchMalls();
    }, []);

    // const fetchCustomers = async () => {
    //     try {
    //         const response = await fetch("https://api.finimaps.com/customers/");
    //         const data = await response.json();
    //         setCustomers(data);
    //     } catch (error) {
    //         console.error("Failed to fetch customers", error);
    //     }
    // };

    // Fetch all malls
    const fetchMalls = async () => {
        try {
            const response = await fetch('https://api.finimaps.com/malls/');
            if (response.ok) {
                const data = await response.json();
                setMalls(data);
            } else {
                throw new Error('Failed to load malls');
            }
        } catch (error) {
            setNotification({message: error.message, type: 'error'});
        }
    };

    // Fetch all Stores
    const fetchStores = async () => {
        try {
            const response = await fetch('https://api.finimaps.com/stores/');
            if (response.ok) {
                const data = await response.json();
                setStores(data);
            } else {
                throw new Error('Failed to load Stores');
            }
        } catch (error) {
            setNotification({message: error.message, type: 'error'});
        }
    };


    // Helper function to get customer name by ID
    const getFloorName = (customerId) => {
        const store = Stores.find(c => c._id === customerId);
        return store ? store.name : 'Unknown Floor';
    };

    // Fetch all Chatbots
    const fetchChatbots = async () => {
        const accessToken = localStorage.getItem('accessToken');
        try {
            const response = await fetch('https://api.finimaps.com/all-bots/', {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${accessToken}`,
                },
            });
            if (response.ok) {
                const data = await response.json();
                setChatbots(data);
            } else {
                throw new Error('Failed to load Chatbots');
            }
        } catch (error) {
            setNotification({message: error.message, type: 'error'});
        }
    };

    // Add a new Chatbot
    // Add a new Chatbot with form-data
    const addChatbot = async () => {
        setLoading(true); // Disable buttons
        const formDataToSend = new FormData();
        formDataToSend.append("mall_id", selectedMallId);
        formDataToSend.append("bot_name", formData.bot_name);
        formDataToSend.append("bot_description", formData.bot_description);
        formDataToSend.append("instruction", formData.instruction);
        formDataToSend.append("icon", file);

        const accessToken = localStorage.getItem('accessToken');
        try {
            const response = await fetch("https://api.finimaps.com/deploy_agent_bot/", {
                method: "POST",
                headers: {
                    'Authorization': `Bearer ${accessToken}`
                },
                body: formDataToSend
            });
            if (response.ok) {
                alert("Chatbot added successfully");
                setFormData({});
                setSelectedMallId("");
                setselectedStoreId("");
                setIsAddModalOpen(false);
                fetchChatbots();
            } else {
                throw new Error("Failed to add Chatbot");
            }
        } catch (error) {
            alert(error.message, "error");
        } finally {
            setLoading(false); // Re-enable buttons
        }
    };


    const openEditModal = (Chatbot) => {
        setSelectedChatbot(Chatbot);
        setFormData({
            Chatbot_type: Chatbot.Chatbot_type,
            description: Chatbot.description,
            Chatbot_url: Chatbot.Chatbot_url,
        });
        setIsEditModalOpen(true);
    };
    // Update a Chatbot by ID
    // Update a Chatbot by ID
    const updateChatbot = async (mall_id, bot_id) => {
        setLoading(true); // Disable buttons
        if (!mall_id || !bot_id) {
            alert("Mall ID or Bot ID is missing.");
            setLoading(false);
            return;
        }

        const formDataToUpdate = new FormData();
        formDataToUpdate.append("mall_id", mall_id);
        formDataToUpdate.append("bot_id", bot_id);

        const accessToken = localStorage.getItem("accessToken");
        try {
            const response = await fetch("https://api.finimaps.com/agent_update/", {
                method: "PUT",
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
                body: formDataToUpdate,
            });

            if (response.ok) {
                alert("Chatbot updated successfully");
                fetchChatbots();
            } else {
                throw new Error("Failed to update Chatbot");
            }
        } catch (error) {
            alert(error.message);
        } finally {
            setLoading(false); // Re-enable buttons
        }
    };


    // Delete a Chatbot by ID
    const deleteChatbot = async (bot_id) => {
        const accessToken = localStorage.getItem('accessToken');

        const confirmed = window.confirm("Are you sure you want to delete this Chatbot?");
        if (!confirmed) return;

        try {
            const response = await fetch(`https://api.finimaps.com/agents/${bot_id}`, {
                method: "DELETE",
                headers: {
                    'Authorization': `Bearer ${accessToken}`
                },
                body: formData
            });
            if (response.ok) {
                alert('Chatbot deleted successfully');
                setChatbots(Chatbots.filter(Chatbot => Chatbot.bot_id !== bot_id));
            } else {
                throw new Error('Failed to delete Chatbot');
            }
        } catch (error) {
            alert(error.message);
        }
    };

    // Export Chatbots to CSV
    const exportChatbots = async () => {
        try {
            const response = await fetch('https://api.finimaps.com/Chatbot/export/');
            if (response.ok) {
                const blob = await response.blob();
                const url = URL.createObjectURL(blob);
                const link = document.createElement('a');
                link.href = url;
                link.download = 'Chatbots.csv';
                link.click();
                alert('Chatbots exported successfully');
            } else {
                throw new Error('Failed to export Chatbots');
            }
        } catch (error) {
            alert(error.message);
        }
    };

    // Import Chatbots from CSV
    const importChatbots = async () => {
        const formData = new FormData();
        formData.append('file', file);

        try {
            const response = await fetch('https://api.finimaps.com/Chatbot/import/', {
                method: 'POST',
                body: formData,
            });
            if (response.ok) {
                alert('Chatbots imported successfully');
                setIsImportModalOpen(false);
                fetchChatbots();
            } else {
                throw new Error('Failed to import Chatbots');
            }
        } catch (error) {
            alert(error.message);
        }
    };

    // Fetch Chatbot details for viewing
    const viewChatbotDetail = async (id) => {
        try {
            const response = await fetch(`https://api.finimaps.com/Chatbot/${id}/detail/`);
            if (response.ok) {
                const data = await response.json();
                setSelectedChatbot(data);
                setIsViewModalOpen(true);
            } else {
                throw new Error('Failed to fetch Chatbot details');
            }
        } catch (error) {
            setNotification({message: error.message, type: 'error'});
        }
    };

    // Close modals when clicking outside
    const closeModal = (e, setModalState) => {
        if (modalRef.current && !modalRef.current.contains(e.target)) {
            setModalState(false);
        }
    };

    const [searchTerm, setSearchTerm] = useState("");

    const handleSearchChange = (e) => {
        setSearchTerm(e.target.value.toLowerCase());
    };

    const filteredChatbots = Chatbots.filter(chatbot =>
        chatbot.bot_name.toLowerCase().includes(searchTerm) ||
        chatbot.description.toLowerCase().includes(searchTerm) ||
        chatbot.email.toLowerCase().includes(searchTerm)
    );

    // Fetch Chatbots on component mount
    useEffect(() => {
        fetchChatbots();
        document.addEventListener("mousedown", (e) => closeModal(e, setIsAddModalOpen));
        document.addEventListener("mousedown", (e) => closeModal(e, setIsEditModalOpen));
        document.addEventListener("mousedown", (e) => closeModal(e, setIsImportModalOpen));
        document.addEventListener("mousedown", (e) => closeModal(e, setIsViewModalOpen));
        return () => {
            document.removeEventListener("mousedown", closeModal);
        };
    }, []);

    return (
        <div className="Chatbot-container">
            <h1 className="Chatbot-title">Chatbot Management</h1>
            <div className="chatbot-search-container">
                <input
                    type="text"
                    placeholder="Search by Email, Bot Name, or Description..."
                    className="chatbot-search-input"
                    value={searchTerm}
                    onChange={handleSearchChange}
                />
            </div>

            {/* Button container */}
            <div className="Chatbot-button-container">
                <button className="Chatbot-action-btn Chatbot-add-btn" onClick={() => setIsAddModalOpen(true)}>Create
                    New
                    Chatbot
                </button>
            </div>

            {/* Chatbot Table */}
            <div className="Chatbot-table-container">
                <table className="Chatbot-table">
                    <thead>
                    <tr>
                        <th>Mall</th>
                        <th>Email</th>
                        <th>Bot Name</th>
                        <th>Description</th>
                        {/*<th>Icon</th>*/}
                        <th>Created At</th>
                        <th>Actions</th>
                    </tr>
                    </thead>
                    <tbody>
                    {filteredChatbots.map((Chatbot) => (
                        <tr key={Chatbot.bot_id}>
                            <td>{Chatbot.mall_name}</td>
                            <td>{Chatbot.email}</td>
                            <td>{Chatbot.bot_name}</td>
                            <td>{Chatbot.description}</td>
                            <td>{Chatbot.created_at}</td>
                            <td>
                                <button
                                    className="Chatbot-action-btn Chatbot-edit-btn"
                                    onClick={() => updateChatbot(Chatbot.mall_id, Chatbot.bot_id)}
                                    disabled={loading}
                                >
                                    {loading ? "Updating..." : "Update"}
                                </button>

                                <button className="Chatbot-action-btn Chatbot-delete-btn"
                                        onClick={() => deleteChatbot(Chatbot.bot_id)}>Delete
                                </button>
                            </td>
                        </tr>
                    ))}
                    </tbody>

                </table>
            </div>

            {/* Add, Edit, Import, and View Modals */}
            {/* Implement similar to CustomerManagement with the updated Chatbot-specific classes */}
            {/* Add Chatbot Modal */}
            {isAddModalOpen && (
                <div className="Chatbot-modal-overlay" onClick={(e) => closeModal(e, setIsAddModalOpen)}>
                    <div className="Chatbot-modal-content" ref={modalRef}>
                        <button className="Chatbot-close-btn" onClick={() => setIsAddModalOpen(false)}>
                            <MdClose/>
                        </button>
                        <h2 className="Chatbot-modal-title">Add New Chatbot</h2>
                        <form
                            onSubmit={(e) => {
                                e.preventDefault();
                                addChatbot();
                            }}
                            className="Chatbot-form-container"
                        >
                            <select
                                value={selectedMallId}
                                onChange={(e) => setSelectedMallId(e.target.value)}
                                required
                                className="mall-form-select"
                            >
                                <option value="">Select Mall</option>
                                {malls.map((mall) => (
                                    <option key={mall._id} value={mall._id}>
                                        {mall.name}
                                    </option>
                                ))}
                            </select>

                            <input
                                type="text"
                                name="bot_name"
                                placeholder="Bot Name"
                                required
                                onChange={(e) => setFormData({...formData, bot_name: e.target.value})}
                            />

                            <input
                                type="text"
                                name="bot_description"
                                placeholder="Description"
                                required
                                onChange={(e) => setFormData({...formData, bot_description: e.target.value})}
                            />

                            <input
                                type="text"
                                name="instruction"
                                placeholder="Instruction"
                                required
                                onChange={(e) => setFormData({...formData, instruction: e.target.value})}
                            />
                            <p>Select Bot Icon</p>
                            <input
                                type="file"
                                name="icon"
                                onChange={(e) => setFile(e.target.files[0])}
                                required
                            />

                            <button
                                type="submit"
                                className="Chatbot-submit-btn"
                                disabled={loading}
                            >
                                {loading ? "Creating..." : "Add Chatbot"}
                            </button>

                        </form>
                    </div>
                </div>
            )}


            {/*/!* Edit Chatbot Modal *!/*/}
            {/*{isEditModalOpen && selectedChatbot && (*/}
            {/*    <div className="Chatbot-modal-overlay" onClick={(e) => closeModal(e, setIsEditModalOpen)}>*/}
            {/*        <div className="Chatbot-modal-content" ref={modalRef}>*/}
            {/*            <button className="Chatbot-close-btn" onClick={() => setIsEditModalOpen(false)}>*/}
            {/*                <MdClose/>*/}
            {/*            </button>*/}
            {/*            <h2 className="Chatbot-modal-title">Edit Chatbot</h2>*/}
            {/*            <form*/}
            {/*                onSubmit={(e) => {*/}
            {/*                    e.preventDefault();*/}
            {/*                    updateChatbot();*/}
            {/*                }}*/}
            {/*                className="Chatbot-form-container"*/}
            {/*            >*/}
            {/*                /!* Chatbot Name *!/*/}
            {/*                <input type="text" name="name" value={selectedChatbot.mall_name}/>*/}
            {/*                <button type="submit" className="Chatbot-submit-btn">Update Chatbot</button>*/}
            {/*            </form>*/}
            {/*        </div>*/}
            {/*    </div>*/}
            {/*)}*/}

            {/* Import Chatbot Modal */}
            {/*{isImportModalOpen && (*/}
            {/*    <div className="Chatbot-import-modal-overlay" onClick={(e) => closeModal(e, setIsImportModalOpen)}>*/}
            {/*        <div className="Chatbot-import-modal-content" ref={modalRef}>*/}
            {/*            <button className="Chatbot-close-btn" onClick={() => setIsImportModalOpen(false)}><MdClose/>*/}
            {/*            </button>*/}
            {/*            <h2 className="Chatbot-import-modal-title">Import Chatbot</h2>*/}
            {/*            <input type="file" className="Chatbot-import-file-input"*/}
            {/*                   onChange={(e) => setFile(e.target.files[0])}/>*/}
            {/*            <button onClick={importChatbots} className="Chatbot-import-action-btn">Import</button>*/}
            {/*        </div>*/}
            {/*    </div>*/}
            {/*)}*/}

            {/* View Chatbot Modal */}
            {/*{isViewModalOpen && selectedChatbot && (*/}
            {/*    <div className="Chatbot-view-modal-overlay" onClick={(e) => closeModal(e, setIsViewModalOpen)}>*/}
            {/*        <div className="Chatbot-view-modal-content" ref={modalRef}>*/}
            {/*            <button className="Chatbot-close-btn" onClick={() => setIsViewModalOpen(false)}><MdClose/>*/}
            {/*            </button>*/}
            {/*            /!*<h2 className="Chatbot-view-modal-title">Chatbot Details</h2>*!/*/}
            {/*            /!*<div className="Chatbot-view-details-container">*!/*/}
            {/*            /!*    <div className="Chatbot-view-detail-row">*!/*/}
            {/*            /!*        <span className="Chatbot-view-detail-label">Name</span>*!/*/}
            {/*            /!*        <span className="Chatbot-view-detail-data">{selectedChatbot.name}</span>*!/*/}
            {/*            /!*    </div>*!/*/}
            {/*            /!*    <div className="Chatbot-view-detail-row">*!/*/}
            {/*            /!*        <span className="Chatbot-view-detail-label">Location</span>*!/*/}
            {/*            /!*        <span className="Chatbot-view-detail-data">{selectedChatbot.location}</span>*!/*/}
            {/*            /!*    </div>*!/*/}
            {/*            /!*    <div className="Chatbot-view-detail-row">*!/*/}
            {/*            /!*        <span className="Chatbot-view-detail-label">Total Area (sqft)</span>*!/*/}
            {/*            /!*        <span className="Chatbot-view-detail-data">{selectedChatbot.total_area_sqft}</span>*!/*/}
            {/*            /!*    </div>*!/*/}
            {/*            /!*    <div className="Chatbot-view-detail-row">*!/*/}
            {/*            /!*        <span className="Chatbot-view-detail-label">Parking Capacity</span>*!/*/}
            {/*            /!*        <span className="Chatbot-view-detail-data">{selectedChatbot.parking_capacity}</span>*!/*/}
            {/*            /!*    </div>*!/*/}
            {/*            /!*    <div className="Chatbot-view-detail-row">*!/*/}
            {/*            /!*        <span className="Chatbot-view-detail-label">Opening Date</span>*!/*/}
            {/*            /!*        <span className="Chatbot-view-detail-data">{selectedChatbot.opening_date}</span>*!/*/}
            {/*            /!*    </div>*!/*/}
            {/*            /!*</div>*!/*/}
            {/*            /!* Chatbots Details Table *!/*/}
            {/*            <div className="Chatbots-floor-container">*/}
            {/*                <h3 className="Chatbots-floor-view-modal-subtitle">Chatbot</h3>*/}
            {/*                {Chatbots && Chatbots.length > 0 ? (*/}
            {/*                    <table className="floor-Chatbots-table">*/}
            {/*                        <thead>*/}
            {/*                        <tr>*/}
            {/*                            <th>Chatbot</th>*/}
            {/*                        </tr>*/}
            {/*                        </thead>*/}
            {/*                        <tbody>*/}
            {/*                        {Chatbots.map((Chatbot) => (*/}
            {/*                            <tr key={Chatbot._id}>*/}
            {/*                                <td>*/}
            {/*                                    {Chatbot.Chatbot_url.match(/\.(jpeg|jpg|gif|png)$/i) ? (*/}
            {/*                                        <img*/}
            {/*                                            src={Chatbot.Chatbot_url}*/}
            {/*                                            alt="Chatbot"*/}
            {/*                                            style={{maxWidth: '100px', maxHeight: '100px'}}*/}
            {/*                                        />*/}
            {/*                                    ) : (*/}
            {/*                                        <a href={Chatbot.Chatbot_url} target="_blank"*/}
            {/*                                           rel="noopener noreferrer">*/}
            {/*                                            {Chatbot.Chatbot_url}*/}
            {/*                                        </a>*/}
            {/*                                    )}*/}
            {/*                                </td>*/}
            {/*                            </tr>*/}
            {/*                        ))}*/}
            {/*                        </tbody>*/}
            {/*                    </table>*/}
            {/*                ) : (*/}
            {/*                    <p className="no-Chatbots-message">No Chatbot yet</p>*/}
            {/*                )}*/}
            {/*            </div>*/}

            {/*        </div>*/}
            {/*    </div>*/}
            {/*)}*/}

        </div>
    );
};

export default ChatbotManagement;
