import React, {useState, useEffect, useRef} from 'react';
import './CustomerManagement.css';
import {MdClose} from 'react-icons/md';
import {Modal} from 'antd';
import {InfoCircleOutlined} from '@ant-design/icons';

const CustomerManagement = () => {
    const [customers, setCustomers] = useState([]);
    const [formData, setFormData] = useState({}); // Data for add/update customer
    const [notification, setNotification] = useState({message: '', type: ''});
    const [file, setFile] = useState(null);
    const [selectedCustomer, setSelectedCustomer] = useState(null);
    const [isAddModalOpen, setIsAddModalOpen] = useState(false);
    const [isEditModalOpen, setIsEditModalOpen] = useState(false);
    const [isImportModalOpen, setIsImportModalOpen] = useState(false);
    const [isViewModalOpen, setIsViewModalOpen] = useState(false);
    const modalRef = useRef();
    const [isDetailsModalOpen, setIsDetailsModalOpen] = useState(false);

    // Fetch all customers
    const fetchCustomers = async () => {
        try {
            const response = await fetch('https://api.finimaps.com/customers/');
            if (response.ok) {
                const data = await response.json();
                setCustomers(data);
            } else {
                throw new Error('Failed to load customers');
            }
        } catch (error) {
            setNotification({message: error.message, type: 'error'});
        }
    };

    // Open details modal
    const showCustomerDetails = (customer) => {
        setSelectedCustomer(customer);
        setIsDetailsModalOpen(true);
    };

    // Close details modal
    const handleDetailsModalClose = () => {
        setIsDetailsModalOpen(false);
        setSelectedCustomer(null);
    };

    // Add a new customer
    const addCustomer = async () => {
        try {
            const response = await fetch('https://api.finimaps.com/add-customers/', {
                method: 'POST',
                headers: {'Content-Type': 'application/json'},
                body: JSON.stringify(formData),
            });
            if (response.ok) {
                alert('Customer added successfully');
                setFormData({});
                setIsAddModalOpen(false); // Close modal on success
                fetchCustomers(); // Refresh customer list
            } else {
                throw new Error('Failed to add customer');
            }
        } catch (error) {
            alert(error.message, 'error');
        }
    };

    // Update a customer by ID
    const updateCustomer = async () => {
        try {
            const response = await fetch(`https://api.finimaps.com/customers/${selectedCustomer._id}`, {
                method: 'PUT',
                headers: {'Content-Type': 'application/json'},
                body: JSON.stringify(formData),
            });
            if (response.ok) {
                alert('Customer updated successfully');
                setIsEditModalOpen(false); // Close modal on success
                fetchCustomers();
            } else {
                throw new Error('Failed to update customer');
            }
        } catch (error) {
            alert(error.message);
        }
    };

    // Delete a customer by ID
    const deleteCustomer = async (id) => {
        const confirmed = window.confirm("Are you sure you want to delete this customer?");
        if (!confirmed) return;

        try {
            const response = await fetch(`https://api.finimaps.com/customers/${id}`, {method: 'DELETE'});
            if (response.ok) {
                alert('Customer deleted successfully');
                setCustomers(customers.filter(customer => customer._id !== id));
            } else {
                throw new Error('Failed to delete customer');
            }
        } catch (error) {
            alert(error.message);
        }
    };

    // Export customers to CSV
    const exportCustomers = async () => {
        try {
            const response = await fetch('https://api.finimaps.com/customers/export/');
            if (response.ok) {
                const blob = await response.blob();
                const url = URL.createObjectURL(blob);
                const link = document.createElement('a');
                link.href = url;
                link.download = 'customers.csv';
                link.click();
                alert('Customers exported successfully');
            } else {
                throw new Error('Failed to export customers');
            }
        } catch (error) {
            alert(error.message);
        }
    };

    // Import customers from CSV
    const importCustomers = async () => {
        const formData = new FormData();
        formData.append('file', file);

        try {
            const response = await fetch('https://api.finimaps.com/customers/import/', {
                method: 'POST',
                body: formData,
            });
            if (response.ok) {
                alert('Customers imported successfully');
                setIsImportModalOpen(false); // Close modal on success
                fetchCustomers();
            } else {
                throw new Error('Failed to import customers');
            }
        } catch (error) {
            alert(error.message);
        }
    };

    // Fetch customer details for viewing
    const viewCustomerDetail = async (id) => {
        try {
            const response = await fetch(`https://api.finimaps.com/customers/${id}/detail/`);
            if (response.ok) {
                const data = await response.json();
                setSelectedCustomer(data);
                setIsViewModalOpen(true);
            } else {
                throw new Error('Failed to fetch customer details');
            }
        } catch (error) {
            setNotification({message: error.message, type: 'error'});
        }
    };

    // Close modals when clicking outside
    const closeModal = (e, setModalState) => {
        if (modalRef.current && !modalRef.current.contains(e.target)) {
            setModalState(false);
        }
    };

    const [searchTerm, setSearchTerm] = useState("");

    const handleSearchChange = (e) => {
        setSearchTerm(e.target.value.toLowerCase());
    };

    const filteredCustomers = customers.filter(customer =>
        customer.name.toLowerCase().includes(searchTerm) ||
        customer.email.toLowerCase().includes(searchTerm) ||
        customer.contact_person.toLowerCase().includes(searchTerm)
    );


    // Fetch customers on component mount
    useEffect(() => {
        fetchCustomers();
        document.addEventListener("mousedown", (e) => closeModal(e, setIsAddModalOpen));
        document.addEventListener("mousedown", (e) => closeModal(e, setIsEditModalOpen));
        document.addEventListener("mousedown", (e) => closeModal(e, setIsImportModalOpen));
        document.addEventListener("mousedown", (e) => closeModal(e, setIsViewModalOpen));
        return () => {
            document.removeEventListener("mousedown", closeModal);
        };
    }, []);

    return (
        <div className="container">
            <h1 className="title">Customer Management</h1>
            {notification.message && (
                <div className={`notification ${notification.type}`}>{notification.message}</div>
            )}

            {/* Customer Table */}
            <div className="search-container">
                <input
                    type="text"
                    placeholder="Search by Name, Email, or Contact Person..."
                    className="search-input"
                    value={searchTerm}
                    onChange={handleSearchChange}
                />
            </div>

            {/* Button container */}
            <div className="button-container">
                <button className="action-btn add-btn" onClick={() => setIsAddModalOpen(true)}>Create New Customer
                </button>
                <button className="action-btn import-btn" onClick={() => setIsImportModalOpen(true)}>Import Customers
                </button>
                <button className="action-btn export-btn" onClick={exportCustomers}>Export Customers</button>
            </div>


            <div className="table-container">
                <table className="table">
                    <thead>
                    <tr>
                        <th>Name</th>
                        <th>Contact Person</th>
                        <th>Email</th>
                        <th>Phone</th>
                        <th>Address</th>
                        <th>Actions</th>
                    </tr>
                    </thead>
                    <tbody>
                    {filteredCustomers.map((customer) => (
                        <tr key={customer._id}>
                            <td>{customer.name}</td>
                            <td>{customer.contact_person}</td>
                            <td>{customer.email}</td>
                            <td>{customer.phone}</td>
                            <td>{customer.address}</td>
                            <td>

                                <button className="action-btn view-btn"
                                        onClick={() => viewCustomerDetail(customer._id)}>
                                    View Malls
                                </button>
                                <button
                                    className="action-btn edit-btn"
                                    onClick={() => {
                                        setSelectedCustomer(customer); // Set the selected customer
                                        setFormData(customer); // Pre-fill the form data
                                        setIsEditModalOpen(true); // Open the modal
                                    }}
                                >
                                    Edit
                                </button>

                                <button className="action-btn delete-btn" onClick={() => deleteCustomer(customer._id)}>
                                    Delete
                                </button>

                                <InfoCircleOutlined className="action-btn"
                                    style={{fontSize: '18px', color: '#1890ff', cursor: 'pointer'}}
                                    onClick={() => showCustomerDetails(customer)}
                                />
                            </td>
                        </tr>
                    ))}
                    </tbody>

                </table>
            </div>

            {/* Customer Details Modal */}
            <Modal
                title="Customer Details"
                open={isDetailsModalOpen}
                onCancel={handleDetailsModalClose}
                footer={null}
                centered
            >
                {selectedCustomer && (
                    <div className="details-container">
                        <p><strong>Name:</strong> {selectedCustomer.name}</p>
                        <p><strong>Contact Person:</strong> {selectedCustomer.contact_person}</p>
                        <p><strong>Email:</strong> {selectedCustomer.email}</p>
                        <p><strong>Phone:</strong> {selectedCustomer.phone}</p>
                        <p><strong>Address:</strong> {selectedCustomer.address}</p>
                        <p><strong>City:</strong> {selectedCustomer.city}</p>
                        <p><strong>State:</strong> {selectedCustomer.state}</p>
                        <p><strong>Country:</strong> {selectedCustomer.country}</p>
                        <p><strong>Postal Code:</strong> {selectedCustomer.postal_code}</p>
                        <p><strong>Contract Dates:</strong> {selectedCustomer.contract_dates || 'N/A'}</p>
                        <p><strong>Industry:</strong> {selectedCustomer.industry || 'N/A'}</p>
                        <p><strong>Created At:</strong> {new Date(selectedCustomer.created_at).toLocaleString()}</p>
                    </div>
                )}
            </Modal>
            {/* Add Customer Modal */}
            {isAddModalOpen && (
                <div className="modal-overlay" onClick={(e) => closeModal(e, setIsAddModalOpen)}>
                    <div className="modal-content" ref={modalRef}>
                        <button className="close-btn" onClick={() => setIsAddModalOpen(false)}><MdClose/></button>
                        <h2 className="modal-title">Add New Customer</h2>
                        <form onSubmit={(e) => {
                            e.preventDefault();
                            addCustomer();
                        }} className="form-container">
                            <input type="text" name="name"
                                   onChange={(e) => setFormData({...formData, name: e.target.value})} placeholder="Name"
                                   required/>
                            <input type="text" name="contact_person"
                                   onChange={(e) => setFormData({...formData, contact_person: e.target.value})}
                                   placeholder="Contact Person" required/>
                            <input type="text" name="phone"
                                   onChange={(e) => setFormData({...formData, phone: e.target.value})}
                                   placeholder="Phone" required/>
                            <input type="email" name="email"
                                   onChange={(e) => setFormData({...formData, email: e.target.value})}
                                   placeholder="Email" required/>
                            <input type="text" name="address"
                                   onChange={(e) => setFormData({...formData, address: e.target.value})}
                                   placeholder="Address" required/>
                            <input type="text" name="city"
                                   onChange={(e) => setFormData({...formData, city: e.target.value})} placeholder="City"
                                   required/>
                            <input type="text" name="state"
                                   onChange={(e) => setFormData({...formData, state: e.target.value})}
                                   placeholder="State" required/>
                            <input type="text" name="country"
                                   onChange={(e) => setFormData({...formData, country: e.target.value})}
                                   placeholder="Country" required/>
                            <input type="text" name="postal_code"
                                   onChange={(e) => setFormData({...formData, postal_code: e.target.value})}
                                   placeholder="Postal Code" required/>

                            <input type="text" name="contract_dates"
                                   onChange={(e) => setFormData({...formData, contract_dates: e.target.value})}
                                   placeholder="Contract Dates e.g Start Date - End Date" required/>
                            <input type="text" name="industry"
                                   onChange={(e) => setFormData({...formData, industry: e.target.value})}
                                   placeholder="Industry" required/>
                            <button type="submit" className="action-btn add-btn submit-btn">Add Customer</button>
                        </form>
                    </div>
                </div>
            )}


            {/* Edit Customer Modal */}
            {isEditModalOpen && (
                <div className="modal-overlay" onClick={(e) => closeModal(e, setIsEditModalOpen)}>
                    <div className="modal-content" ref={modalRef}>
                        <button className="close-btn" onClick={() => setIsEditModalOpen(false)}><MdClose/></button>
                        <h2 className="modal-title">Edit Customer</h2>
                        <form onSubmit={(e) => {
                            e.preventDefault();
                            updateCustomer();
                        }} className="form-container">
                            <input type="text" name="name" value={formData.name || ''}
                                   onChange={(e) => setFormData({...formData, name: e.target.value})} placeholder="Name"
                                   required/>
                            <input type="text" name="contact_person" value={formData.contact_person || ''}
                                   onChange={(e) => setFormData({...formData, contact_person: e.target.value})}
                                   placeholder="Contact Person" required/>
                            <input type="text" name="phone" value={formData.phone || ''}
                                   onChange={(e) => setFormData({...formData, phone: e.target.value})}
                                   placeholder="Phone" required/>
                            <input type="email" name="email" value={formData.email || ''}
                                   onChange={(e) => setFormData({...formData, email: e.target.value})}
                                   placeholder="Email" required/>
                            <input type="text" name="address" value={formData.address || ''}
                                   onChange={(e) => setFormData({...formData, address: e.target.value})}
                                   placeholder="Address" required/>
                            <input type="text" name="city" value={formData.city || ''}
                                   onChange={(e) => setFormData({...formData, city: e.target.value})} placeholder="City"
                                   required/>
                            <input type="text" name="state" value={formData.state || ''}
                                   onChange={(e) => setFormData({...formData, state: e.target.value})}
                                   placeholder="State" required/>
                            <input type="text" name="country" value={formData.country || ''}
                                   onChange={(e) => setFormData({...formData, country: e.target.value})}
                                   placeholder="Country" required/>
                            <input type="text" name="postal_code" value={formData.postal_code || ''}
                                   onChange={(e) => setFormData({...formData, postal_code: e.target.value})}
                                   placeholder="Postal Code" required/>
                            <input type="text" name="contract_dates" value={formData.contract_dates || ''}
                                   onChange={(e) => setFormData({...formData, contract_dates: e.target.value})}
                                   placeholder="Contract Dates e.g Start Date - End Date" required/>
                            <input type="text" name="industry" value={formData.industry || ''}
                                   onChange={(e) => setFormData({...formData, industry: e.target.value})}
                                   placeholder="Industry" required/>
                            <button type="submit" className="action-btn edit-btn submit-btn">Update Customer</button>
                        </form>
                    </div>
                </div>
            )}

            {/* Import Customer Modal */}
            {isImportModalOpen && (
                <div className="import-modal-overlay" onClick={(e) => closeModal(e, setIsImportModalOpen)}>
                    <div className="import-modal-content" ref={modalRef}>
                        <button className="import-close-btn" onClick={() => setIsImportModalOpen(false)}><MdClose/>
                        </button>
                        <h2 className="import-modal-title">Import Customers</h2>
                        <input type="file" className="import-file-input" onChange={(e) => setFile(e.target.files[0])}/>
                        <button onClick={importCustomers} className="import-action-btn">Import</button>
                    </div>
                </div>
            )}


            {/* View Customer Modal */}
            {/* View Customer Modal */}
            {isViewModalOpen && selectedCustomer && (
                <div className="view-modal-overlay" onClick={(e) => closeModal(e, setIsViewModalOpen)}>
                    <div className="view-modal-content" ref={modalRef}>
                        <button className="view-close-btn" onClick={() => setIsViewModalOpen(false)}><MdClose/></button>
                        {/*<h2 className="view-modal-title">Customer Details</h2>*/}
                        {/*<div className="view-details-container">*/}
                        {/*    /!* Customer Basic Details *!/*/}
                        {/*    <div className="view-detail-row">*/}
                        {/*        <span className="view-detail-label">Name</span>*/}
                        {/*        <span className="view-detail-data">{selectedCustomer.name}</span>*/}
                        {/*    </div>*/}
                        {/*    <div className="view-detail-row">*/}
                        {/*        <span className="view-detail-label">Contact Person</span>*/}
                        {/*        <span className="view-detail-data">{selectedCustomer.contact_person}</span>*/}
                        {/*    </div>*/}
                        {/*    <div className="view-detail-row">*/}
                        {/*        <span className="view-detail-label">Phone</span>*/}
                        {/*        <span className="view-detail-data">{selectedCustomer.phone}</span>*/}
                        {/*    </div>*/}
                        {/*    <div className="view-detail-row">*/}
                        {/*        <span className="view-detail-label">Email</span>*/}
                        {/*        <span className="view-detail-data">{selectedCustomer.email}</span>*/}
                        {/*    </div>*/}
                        {/*    <div className="view-detail-row">*/}
                        {/*        <span className="view-detail-label">Address</span>*/}
                        {/*        <span className="view-detail-data">{selectedCustomer.address}</span>*/}
                        {/*    </div>*/}
                        {/*    <div className="view-detail-row">*/}
                        {/*        <span className="view-detail-label">City</span>*/}
                        {/*        <span className="view-detail-data">{selectedCustomer.city}</span>*/}
                        {/*    </div>*/}
                        {/*    <div className="view-detail-row">*/}
                        {/*        <span className="view-detail-label">State</span>*/}
                        {/*        <span className="view-detail-data">{selectedCustomer.state}</span>*/}
                        {/*    </div>*/}
                        {/*    <div className="view-detail-row">*/}
                        {/*        <span className="view-detail-label">Country</span>*/}
                        {/*        <span className="view-detail-data">{selectedCustomer.country}</span>*/}
                        {/*    </div>*/}
                        {/*    <div className="view-detail-row">*/}
                        {/*        <span className="view-detail-label">Postal Code</span>*/}
                        {/*        <span className="view-detail-data">{selectedCustomer.postal_code}</span>*/}
                        {/*    </div>*/}
                        {/*</div>*/}

                        {/* Malls Details Table */}
                        <div className="malls-container">
                            <h3 className="view-modal-subtitle">Malls</h3>
                            {selectedCustomer.malls && selectedCustomer.malls.length > 0 ? (
                                <table className="malls-table">
                                    <thead>
                                    <tr>
                                        <th>Name</th>
                                        <th>Location</th>
                                        <th>Total Area (sqft)</th>
                                        <th>Parking Capacity</th>
                                        <th>Opening Date</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {selectedCustomer.malls.map((mall) => (
                                        <tr key={mall._id}>
                                            <td>{mall.name}</td>
                                            <td>{mall.location}</td>
                                            <td>{mall.total_area_sqft}</td>
                                            <td>{mall.parking_capacity}</td>
                                            <td>{new Date(mall.opening_date).toLocaleDateString()}</td>
                                        </tr>
                                    ))}
                                    </tbody>
                                </table>
                            ) : (
                                <p className="no-malls-message">No malls yet</p>
                            )}
                        </div>
                    </div>
                </div>
            )}


        </div>
    );
};

export default CustomerManagement;
