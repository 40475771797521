import React, {useState, useEffect, useRef} from 'react';
import './StoreManagement.css';
import {MdClose} from 'react-icons/md';
import {Modal, Button} from 'antd';
import {EyeOutlined, DeleteOutlined, EditOutlined, PlusOutlined,} from '@ant-design/icons';

const StoreManagement = () => {
    const [Stores, setStores] = useState([]);
    const [formData, setFormData] = useState({});
    const [notification, setNotification] = useState({message: '', type: ''});
    const [file, setFile] = useState(null);
    const [selectedStore, setSelectedStore] = useState(null);
    const [isAddModalOpen, setIsAddModalOpen] = useState(false);
    const [isEditModalOpen, setIsEditModalOpen] = useState(false);
    const [isImportModalOpen, setIsImportModalOpen] = useState(false);
    const [isViewModalOpen, setIsViewModalOpen] = useState(false);
    const modalRef = useRef();
    const [customers, setCustomers] = useState([]);
    const [selectedCustomerId, setSelectedCustomerId] = useState("");
    const [floors, setFloors] = useState([]);
    const [malls, setMalls] = useState([]);
    const [selectedMallId, setSelectedMallId] = useState("");
    const [selectedFloorId, setSelectedFloorId] = useState("");
    const [floorMap, setFloorMap] = useState({});
    const [mallMap, setMallMap] = useState({});

    const [isDetailsModalVisible, setIsDetailsModalVisible] = useState(false);


    // Fetch customers on component mount
    useEffect(() => {
        fetchMalls();
    }, []);

    const [searchTerm, setSearchTerm] = useState("");

    const handleSearchChange = (e) => {
        setSearchTerm(e.target.value.toLowerCase());
    };

    const filteredStores = Stores.filter(store =>
        store.name.toLowerCase().includes(searchTerm) ||
        store.description.toLowerCase().includes(searchTerm) ||
        store.address.toLowerCase().includes(searchTerm)
    );

    // const fetchCustomers = async () => {
    //     try {
    //         const response = await fetch("https://api.finimaps.com/customers/");
    //         const data = await response.json();
    //         setCustomers(data);
    //     } catch (error) {
    //         console.error("Failed to fetch customers", error);
    //     }
    // };

    // Fetch all malls
    const fetchMalls = async () => {
        try {
            const response = await fetch('https://api.finimaps.com/malls/');
            if (response.ok) {
                const data = await response.json();
                setMalls(data);
            } else {
                throw new Error('Failed to load malls');
            }
        } catch (error) {
            setNotification({message: error.message, type: 'error'});
        }
    };

    // Fetch all floors and open the modal
    const fetchFloors = async (mallId) => {
        try {
            const response = await fetch(`https://api.finimaps.com/floors/${mallId}`);
            if (response.ok) {
                const data = await response.json();
                // console.log(data);  // Log the response data to confirm structure
                setFloors(data);  // Assuming `data` is an array of floors; adjust if necessary
            } else {
                throw new Error('Failed to load floors');
            }
        } catch (error) {
            setNotification({message: error.message, type: 'error'});
        }
    };


    // Helper function to get customer name by ID
    const getCustomerName = (customerId) => {
        const floor = floors.find(c => c._id === customerId);
        return floor ? floor.name : 'Unknown Floor';
    };

    // Fetch all Stores
    const fetchStores = async () => {
        try {
            const response = await fetch('https://api.finimaps.com/stores/');
            if (response.ok) {
                const data = await response.json();
                setStores(data);
            } else {
                throw new Error('Failed to load Stores');
            }
        } catch (error) {
            setNotification({message: error.message, type: 'error'});
        }
    };

    const showDetailsModal = (store) => {
        setSelectedStore(store);
        setIsDetailsModalVisible(true);
    };

    const handleDetailsModalClose = () => {
        setIsDetailsModalVisible(false);
        setSelectedStore(null);
    };

    // Add a new Store
    const addStore = async () => {
        try {
            const response = await fetch("https://api.finimaps.com/stores/", {
                method: "POST",
                headers: {"Content-Type": "application/json"},
                body: JSON.stringify({
                    floor_id: selectedFloorId,  // Floor ID from the selected floor dropdown
                    name: formData.name,
                    description: formData.description,
                    open_close_info: formData.open_close_info,
                    address: formData.address,
                    latitude: parseFloat(formData.latitude),  // Ensure latitude is a number
                    longitude: parseFloat(formData.longitude)  // Ensure longitude is a number
                }),
            });
            if (response.ok) {
                alert("Store added successfully");
                setFormData({});
                setSelectedMallId("");
                setSelectedFloorId("");
                setIsAddModalOpen(false);
                fetchStores();
            } else {
                throw new Error("Failed to add Store");
            }
        } catch (error) {
            alert(error.message, "error");
        }
    };


    const openEditModal = (store) => {
        setSelectedStore(store);
        setFormData({
            name: store.name,
            short_description: store.description,
            description: store.description,
            open_close_info: store.open_close_info,
            address: store.address,
            latitude: parseFloat(store.latitude),  // Ensure latitude is a number
            longitude: parseFloat(store.longitude),  // Ensure longitude is a number
            external_link: store.external_link,
        });
        setIsEditModalOpen(true);
    };
    // Update a Store by ID
    // Update a Store by ID
    const updateStore = async () => {
        try {
            if (!selectedStore) return;

            const response = await fetch(`https://api.finimaps.com/stores/${selectedStore._id}`, {
                method: "PUT",
                headers: {"Content-Type": "application/json"},
                body: JSON.stringify({
                    name: formData.name,
                    short_description: formData.short_description,
                    description: formData.description,
                    open_close_info: formData.open_close_info,
                    address: formData.address,
                    latitude: parseFloat(formData.latitude),  // Ensure latitude is a number
                    longitude: parseFloat(formData.longitude),  // Ensure longitude is a number
                    external_link: formData.external_link,
                }),
            });
            if (response.ok) {
                alert("Store updated successfully");
                setIsEditModalOpen(false);
                fetchStores();
            } else {
                throw new Error("Failed to update Store");
            }
        } catch (error) {
            alert(error.message);
        }
    };

    // Delete a Store by ID
    const deleteStore = async (id) => {
        const confirmed = window.confirm("Are you sure you want to delete this Store?");
        if (!confirmed) return;

        try {
            const response = await fetch(`https://api.finimaps.com/stores/${id}`, {method: 'DELETE'});
            if (response.ok) {
                alert('Store deleted successfully');
                setStores(Stores.filter(Store => Store._id !== id));
            } else {
                throw new Error('Failed to delete Store');
            }
        } catch (error) {
            alert(error.message);
        }
    };

    // Export Stores to CSV
    const exportStores = async () => {
        try {
            const response = await fetch('https://api.finimaps.com/stores/export/');
            if (response.ok) {
                const blob = await response.blob();
                const url = URL.createObjectURL(blob);
                const link = document.createElement('a');
                link.href = url;
                link.download = 'Stores.csv';
                link.click();
                alert('Stores exported successfully');
            } else {
                throw new Error('Failed to export Stores');
            }
        } catch (error) {
            alert(error.message);
        }
    };

    // Import Stores from CSV
    const importStores = async () => {
        if (!selectedFloorId) {
            alert("Please select a floor before importing.");
            return;
        }

        const formData = new FormData();
        formData.append('file', file);
        formData.append('floor_id', selectedFloorId); // Append the selected floor ID

        try {
            const response = await fetch('https://api.finimaps.com/stores/import/', {
                method: 'POST',
                body: formData,
            });
            if (response.ok) {
                alert('Stores imported successfully');
                setIsImportModalOpen(false);
                fetchStores();
            } else {
                throw new Error('Failed to import Stores');
            }
        } catch (error) {
            alert(error.message);
        }
    };


    // Fetch Store details for viewing
    const viewStoreDetail = async (id) => {
        try {
            const response = await fetch(`https://api.finimaps.com/stores/${id}/detail/`);
            if (response.ok) {
                const data = await response.json();
                setSelectedStore(data);
                setIsViewModalOpen(true);
            } else {
                throw new Error('Failed to fetch Store details');
            }
        } catch (error) {
            setNotification({message: error.message, type: 'error'});
        }
    };

    // Close modals when clicking outside
    const closeModal = (e, setModalState) => {
        if (modalRef.current && !modalRef.current.contains(e.target)) {
            setModalState(false);
        }
    };

    // Fetch Stores on component mount
    useEffect(() => {
        fetchStores();
        document.addEventListener("mousedown", (e) => closeModal(e, setIsAddModalOpen));
        document.addEventListener("mousedown", (e) => closeModal(e, setIsEditModalOpen));
        document.addEventListener("mousedown", (e) => closeModal(e, setIsImportModalOpen));
        document.addEventListener("mousedown", (e) => closeModal(e, setIsViewModalOpen));
        return () => {
            document.removeEventListener("mousedown", closeModal);
        };
    }, []);

    return (
        <div className="Store-container">
            <h1 className="Store-title">Store Management</h1>
            <div className="store-search-container">
                <input
                    type="text"
                    placeholder="Search by Store Name, Description, or Address..."
                    className="store-search-input"
                    value={searchTerm}
                    onChange={handleSearchChange}
                />
            </div>

            {/* Button container */}
            <div className="Store-button-container">
                <button className="Store-action-btn Store-add-btn" onClick={() => setIsAddModalOpen(true)}>Create New
                    Store
                </button>
                <button className="Store-action-btn Store-import-btn" onClick={() => setIsImportModalOpen(true)}>Import
                    Stores
                </button>
                <button className="Store-action-btn Store-export-btn" onClick={exportStores}>Export Stores</button>
            </div>

            {/* Store Table */}
            <div className="Store-table-container">
                <table className="Store-table">
                    <thead>
                    <tr>
                        <th>Name</th>
                        <th>Short Description</th>
                        <th>Open Close Info</th>
                        <th>Address</th>
                        <th>Actions</th>
                    </tr>
                    </thead>
                    <tbody>
                    {filteredStores.map((store) => (
                        <tr key={store._id}>
                            <td>{store.name}</td>
                            <td>{store.short_description || 'N/A'}</td>
                            <td>{store.open_close_info}</td>
                            <td>{store.address}</td>
                            <td>
                                <button className="Store-action-btn Store-view-btn"
                                        onClick={() => viewStoreDetail(store._id)}>
                                    View Events
                                </button>
                                <button className="Store-action-btn Store-edit-btn"
                                        onClick={() => openEditModal(store)}>
                                    Edit
                                </button>
                                <button className="Store-action-btn Store-delete-btn"
                                        onClick={() => deleteStore(store._id)}>
                                    Delete
                                </button>
                                <Button
                                    type="primary"
                                    icon={<EyeOutlined/>}
                                    onClick={() => showDetailsModal(store)}
                                >
                                </Button>
                            </td>
                        </tr>
                    ))}
                    </tbody>

                </table>
            </div>

            {/* Ant Design Modal for Store Details */}
            {selectedStore && (
                <Modal
                    title="Store Details"
                    open={isDetailsModalVisible}
                    onCancel={handleDetailsModalClose}
                    footer={[
                        <Button key="close" onClick={handleDetailsModalClose}>
                            Close
                        </Button>,
                    ]}
                >
                    <p><strong>Name:</strong> {selectedStore.name}</p>
                    <p><strong>Short Description:</strong> {selectedStore.short_description || 'N/A'}</p>
                    <p><strong>Description:</strong> {selectedStore.description}</p>
                    <p><strong>Open/Close Info:</strong> {selectedStore.open_close_info}</p>
                    <p><strong>Address:</strong> {selectedStore.address}</p>
                    <p><strong>Latitude:</strong> {selectedStore.latitude}</p>
                    <p><strong>Longitude:</strong> {selectedStore.longitude}</p>
                    <p><strong>External Link:</strong> {selectedStore.external_link || 'N/A'}</p>
                </Modal>
            )}
            {/* Add, Edit, Import, and View Modals */}
            {/* Implement similar to CustomerManagement with the updated Store-specific classes */}
            {/* Add Store Modal */}
            {isAddModalOpen && (
                <div className="Store-modal-overlay" onClick={(e) => closeModal(e, setIsAddModalOpen)}>
                    <div className="Store-modal-content" ref={modalRef}>
                        <button className="Store-close-btn" onClick={() => setIsAddModalOpen(false)}>
                            <MdClose/>
                        </button>
                        <h2 className="Store-modal-title">Add New Store</h2>
                        <form
                            onSubmit={(e) => {
                                e.preventDefault();
                                addStore();
                            }}
                            className="Store-form-container"
                        >
                            {/* Select Mall */}
                            <select
                                value={selectedMallId}
                                onChange={async (e) => {
                                    const mallId = e.target.value;
                                    setSelectedMallId(mallId);
                                    await fetchFloors(mallId);  // Fetch floors when a mall is selected
                                }}
                                required
                                className="Store-form-select"
                            >
                                <option value="">Select Mall</option>
                                {malls.map((mall) => (
                                    <option key={mall._id} value={mall._id}>
                                        {mall.name} - {mall.location}
                                    </option>
                                ))}
                            </select>

                            {/* Select Floor */}
                            <select
                                value={selectedFloorId}
                                onChange={(e) => setSelectedFloorId(e.target.value)}
                                required
                                className="Store-form-select"
                            >
                                <option value="">Select Floor</option>
                                {floors.map((floor) => (
                                    <option key={floor._id} value={floor._id}>
                                        {floor.label}
                                    </option>
                                ))}
                            </select>

                            {/* Store Name */}
                            <input
                                type="text"
                                name="name"
                                placeholder="Store Name"
                                required
                                onChange={(e) => setFormData({...formData, name: e.target.value})}
                            />

                            <input
                                type="text"
                                name="short_description"
                                placeholder="Short Description"
                                required
                                onChange={(e) => setFormData({...formData, short_description: e.target.value})}
                            />

                            {/* Description */}
                            <input
                                type="text"
                                name="description"
                                placeholder="Long Description"
                                required
                                onChange={(e) => setFormData({...formData, description: e.target.value})}
                            />

                            {/* Open Close Info */}
                            <input
                                type="text"  // Change this to "text" as it's a string field, not a number
                                name="open_close_info"
                                placeholder="Open Close Info (e.g., Open 9 AM - 9 PM)"
                                required
                                onChange={(e) => setFormData({...formData, open_close_info: e.target.value})}
                            />

                            {/* Address */}
                            <input
                                type="text"  // Change this to "text" as it's a string field
                                name="address"
                                placeholder="Address"
                                required
                                onChange={(e) => setFormData({...formData, address: e.target.value})}
                            />

                            {/* Latitude */}
                            <input
                                type="number"
                                step="any"  // Allows decimal input
                                name="latitude"
                                placeholder="Latitude"
                                required
                                onChange={(e) => setFormData({...formData, latitude: e.target.value})}
                            />

                            {/* Longitude */}
                            <input
                                type="number"
                                step="any"  // Allows decimal input
                                name="longitude"
                                placeholder="Longitude"
                                required
                                onChange={(e) => setFormData({...formData, longitude: e.target.value})}
                            />

                            <input
                                type="text"
                                name="external_link"
                                placeholder="External Link"
                                required
                                onChange={(e) => setFormData({...formData, external_link: e.target.value})}
                            />


                            <button type="submit" className="Store-submit-btn">Add Store</button>
                        </form>
                    </div>
                </div>
            )}

            {/* Edit Store Modal */}
            {isEditModalOpen && selectedStore && (
                <div className="Store-modal-overlay" onClick={(e) => closeModal(e, setIsEditModalOpen)}>
                    <div className="Store-modal-content" ref={modalRef}>
                        <button className="Store-close-btn" onClick={() => setIsEditModalOpen(false)}>
                            <MdClose/>
                        </button>
                        <h2 className="Store-modal-title">Edit Store</h2>
                        <form
                            onSubmit={(e) => {
                                e.preventDefault();
                                updateStore();
                            }}
                            className="Store-form-container"
                        >
                            {/* Store Name */}
                            <input
                                type="text"
                                name="name"
                                value={formData.name || ""}
                                placeholder="Store Name"
                                required
                                onChange={(e) => setFormData({...formData, name: e.target.value})}
                            />

                            <input
                                type="text"
                                name="short_description"
                                value={formData.short_description || ""}
                                placeholder="Short Description"
                                required
                                onChange={(e) => setFormData({...formData, short_description: e.target.value})}
                            />

                            {/* Description */}
                            <input
                                type="text"
                                name="description"
                                value={formData.description || ''}
                                placeholder="Long Description"
                                required
                                onChange={(e) => setFormData({...formData, description: e.target.value})}
                            />

                            {/* Open Close Info */}
                            <input
                                type="text"  // Change this to "text" as it's a string field, not a number
                                name="open_close_info"
                                value={formData.open_close_info || ''}
                                placeholder="Open Close Info (e.g., Open 9 AM - 9 PM)"
                                required
                                onChange={(e) => setFormData({...formData, open_close_info: e.target.value})}
                            />

                            {/* Address */}
                            <input
                                type="text"  // Change this to "text" as it's a string field
                                name="address"
                                value={formData.address || ''}
                                placeholder="Address"
                                required
                                onChange={(e) => setFormData({...formData, address: e.target.value})}
                            />

                            {/* Latitude */}
                            <input
                                type="number"
                                step="any"  // Allows decimal input
                                name="latitude"
                                value={formData.latitude || ''}
                                placeholder="Latitude"
                                required
                                onChange={(e) => setFormData({...formData, latitude: e.target.value})}
                            />

                            {/* Longitude */}
                            <input
                                type="number"
                                step="any"  // Allows decimal input
                                value={formData.longitude || ''}
                                name="longitude"
                                placeholder="Longitude"
                                required
                                onChange={(e) => setFormData({...formData, longitude: e.target.value})}
                            />

                            <input
                                type="text"
                                name="external_link"
                                value={formData.external_link || ""}
                                placeholder="External Link"
                                required
                                onChange={(e) => setFormData({...formData, external_link: e.target.value})}
                            />
                            <button type="submit" className="Store-submit-btn">Update Store</button>
                        </form>
                    </div>
                </div>
            )}

            {/* Import Store Modal */}
            {/* Import Store Modal */}
            {isImportModalOpen && (
                <div className="Store-import-modal-overlay" onClick={(e) => closeModal(e, setIsImportModalOpen)}>
                    <div className="Store-import-modal-content" ref={modalRef}>
                        <button className="Store-close-btn" onClick={() => setIsImportModalOpen(false)}><MdClose/>
                        </button>
                        <h2 className="Store-import-modal-title">Import Stores</h2>

                        {/* Select Mall */}
                        <select
                            value={selectedMallId}
                            onChange={async (e) => {
                                const mallId = e.target.value;
                                setSelectedMallId(mallId);
                                await fetchFloors(mallId); // Fetch floors when a mall is selected
                            }}
                            required
                            className="Store-form-select"
                        >
                            <option value="">Select Mall</option>
                            {malls.map((mall) => (
                                <option key={mall._id} value={mall._id}>
                                    {mall.name} - {mall.location}
                                </option>
                            ))}
                        </select>

                        {/* Select Floor */}
                        <select
                            value={selectedFloorId}
                            onChange={(e) => setSelectedFloorId(e.target.value)}
                            required
                            className="Store-form-select"
                        >
                            <option value="">Select Floor</option>
                            {floors.map((floor) => (
                                <option key={floor._id} value={floor._id}>
                                    {floor.label}
                                </option>
                            ))}
                        </select>

                        {/* File Input */}
                        <input
                            type="file"
                            className="Store-import-file-input"
                            onChange={(e) => setFile(e.target.files[0])}
                        />

                        {/* Import Button */}
                        <button onClick={importStores} className="Store-import-action-btn">
                            Import
                        </button>
                    </div>
                </div>
            )}


            {/* View Store Modal */}
            {isViewModalOpen && selectedStore && (
                <div className="Store-view-modal-overlay" onClick={(e) => closeModal(e, setIsViewModalOpen)}>
                    <div className="Store-view-modal-content" ref={modalRef}>
                        <button className="Store-close-btn" onClick={() => setIsViewModalOpen(false)}><MdClose/>
                        </button>
                        {/*<h2 className="Store-view-modal-title">Store Details</h2>*/}
                        {/*<div className="Store-view-details-container">*/}
                        {/*    <div className="Store-view-detail-row">*/}
                        {/*        <span className="Store-view-detail-label">Name</span>*/}
                        {/*        <span className="Store-view-detail-data">{selectedStore.name}</span>*/}
                        {/*    </div>*/}
                        {/*    <div className="Store-view-detail-row">*/}
                        {/*        <span className="Store-view-detail-label">Location</span>*/}
                        {/*        <span className="Store-view-detail-data">{selectedStore.location}</span>*/}
                        {/*    </div>*/}
                        {/*    <div className="Store-view-detail-row">*/}
                        {/*        <span className="Store-view-detail-label">Total Area (sqft)</span>*/}
                        {/*        <span className="Store-view-detail-data">{selectedStore.total_area_sqft}</span>*/}
                        {/*    </div>*/}
                        {/*    <div className="Store-view-detail-row">*/}
                        {/*        <span className="Store-view-detail-label">Parking Capacity</span>*/}
                        {/*        <span className="Store-view-detail-data">{selectedStore.parking_capacity}</span>*/}
                        {/*    </div>*/}
                        {/*    <div className="Store-view-detail-row">*/}
                        {/*        <span className="Store-view-detail-label">Opening Date</span>*/}
                        {/*        <span className="Store-view-detail-data">{selectedStore.opening_date}</span>*/}
                        {/*    </div>*/}
                        {/*</div>*/}
                        {/* Stores Details Table */}
                        <div className="Stores-floor-container">
                            <h3 className="Stores-floor-view-modal-subtitle">Events</h3>
                            {selectedStore.events && selectedStore.events.length > 0 ? (
                                <table className="floor-Stores-table">
                                    <thead>
                                    <tr>
                                        <th>Type</th>
                                        <th>Name</th>
                                        <th>Description</th>
                                        <th>Start Time</th>
                                        <th>End Time</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {selectedStore.events.map((event) => (
                                        <tr key={event._id}>
                                            <td>{event.event_type}</td>
                                            <td>{event.title}</td>
                                            <td>{event.description}</td>
                                            <td>{event.start_time}</td>
                                            <td>{event.end_time}</td>
                                        </tr>
                                    ))}
                                    </tbody>
                                </table>
                            ) : (
                                <p className="no-Stores-message">No Events yet</p>
                            )}
                        </div>
                    </div>
                </div>
            )}

        </div>
    );
};

export default StoreManagement;
