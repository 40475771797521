import React, {useState, useEffect, useRef} from 'react';
import './FloorManagement.css';
import {MdClose} from 'react-icons/md';

const FloorManagement = () => {
    const [floors, setFloors] = useState([]);
    const [formData, setFormData] = useState({});
    const [notification, setNotification] = useState({message: '', type: ''});
    const [file, setFile] = useState(null);
    const [selectedMall, setSelectedMall] = useState(null);
    const [isAddModalOpen, setIsAddModalOpen] = useState(false);
    const [isEditModalOpen, setIsEditModalOpen] = useState(false);
    const [isImportModalOpen, setIsImportModalOpen] = useState(false);
    const [isViewModalOpen, setIsViewModalOpen] = useState(false);
    const modalRef = useRef();
    const [customers, setCustomers] = useState([]);
    const [malls, setMalls] = useState([]);
    const [selectedMallId, setSelectedMallId] = useState("");
    const [addSelectedMallId, setaddSelectedMallId] = useState("");

    // Fetch customers on component mount
    useEffect(() => {
        fetchCustomers();
        fetchMalls();
    }, []);

    const fetchCustomers = async () => {
        try {
            const response = await fetch("https://api.finimaps.com/customers/");
            const data = await response.json();
            setCustomers(data);
        } catch (error) {
            console.error("Failed to fetch customers", error);
        }
    };

    // Fetch all malls
    const fetchMalls = async () => {
        try {
            const response = await fetch('https://api.finimaps.com/malls/');
            if (response.ok) {
                const data = await response.json();
                setMalls(data);
            } else {
                throw new Error('Failed to load malls');
            }
        } catch (error) {
            setNotification({message: error.message, type: 'error'});
        }
    };
    // Helper function to get customer name by ID
    const getMallName = (customerId) => {
        const mall = malls.find(c => c._id === customerId);
        return mall ? mall.name : 'Unknown Customer';
    };


    // Fetch mall details for viewing
    const viewFloorDetails = async (id) => {
        try {
            const response = await fetch(`https://api.finimaps.com/floors/${id}/detail/`);
            if (response.ok) {
                const data = await response.json();
                setSelectedMall(data);
                setIsViewModalOpen(true);
            } else {
                throw new Error('Failed to fetch mall details');
            }
        } catch (error) {
            setNotification({message: error.message, type: 'error'});
        }
    };

    // Fetch all floors and open the modal
    const fetchFloors = async (id) => {
        try {
            const response = await fetch(`https://api.finimaps.com/floors/${id}`);
            if (response.ok) {
                const data = await response.json();
                setFloors(data);
            } else {
                throw new Error('Failed to load floors');
            }
        } catch (error) {
            setNotification({message: error.message, type: 'error'});
        }
    };

    // Add a new mall
    const addMall = async () => {
        try {
            const response = await fetch('https://api.finimaps.com/floors/', {
                method: 'POST',
                headers: {'Content-Type': 'application/json'},
                body: JSON.stringify({
                    mall_id: addSelectedMallId,
                    label: formData.label,
                    order: parseInt(formData.order, 10), // Ensure order is sent as a number
                }),
            });
            if (response.ok) {
                alert('Floor added successfully');
                setFormData({});
                setaddSelectedMallId(false);
                setIsAddModalOpen(false);
            } else {
                throw new Error('Failed to add mall');
            }
        } catch (error) {
            alert(error.message, 'error');
        }
    };


    // Update a floor by ID
    const updateMall = async () => {
        try {
            if (!selectedMall || !selectedMall._id) return;

            const response = await fetch(`https://api.finimaps.com/floors/${selectedMall._id}`, {
                method: "PUT",
                headers: {"Content-Type": "application/json"},
                body: JSON.stringify({
                    mall_id: selectedMallId,
                    label: formData.label,
                    order: parseInt(formData.order, 10), // Ensure order is sent as a number
                }),
            });
            if (response.ok) {
                alert("Floor updated successfully");
                setIsEditModalOpen(false);
                fetchFloors(selectedMallId); // Refresh floors list
            } else {
                throw new Error("Failed to update floor");
            }
        } catch (error) {
            alert(error.message);
        }
    };

// Edit button click handler to set the selected floor and form data
    const handleEditClick = (floor) => {
        setSelectedMall(floor); // Set selected floor to edit
        setFormData({
            label: floor.label,
            order: floor.order,
        });
        setIsEditModalOpen(true);
    };


    // Delete a mall by ID
    const deleteMall = async (id) => {
        const confirmed = window.confirm("Are you sure you want to delete this Floor?");
        if (!confirmed) return;

        try {
            const response = await fetch(`https://api.finimaps.com/floors/${id}`, {method: 'DELETE'});
            if (response.ok) {
                alert('Floor deleted successfully');
                setFloors(floors.filter(mall => mall._id !== id));
            } else {
                throw new Error('Failed to delete Floor');
            }
        } catch (error) {
            alert(error.message);
        }
    };

    // Export floors to CSV
    const exportFloors = async () => {
        try {
            const response = await fetch('https://api.finimaps.com/floors/export/');
            if (response.ok) {
                const blob = await response.blob();
                const url = URL.createObjectURL(blob);
                const link = document.createElement('a');
                link.href = url;
                link.download = 'floors.csv';
                link.click();
                alert('floors exported successfully');
            } else {
                throw new Error('Failed to export floors');
            }
        } catch (error) {
            alert(error.message);
        }
    };

    // Import floors from CSV
    const importFloors = async () => {
        if (!file) {
            alert("Please select a file to upload.");
            return;
        }
        if (!selectedMallId) {
            alert("Please select a mall.");
            return;
        }

        const formData = new FormData();
        formData.append('file', file); // Append the file
        formData.append('mall_id', selectedMallId); // Append the selected mall ID

        try {
            const response = await fetch('https://api.finimaps.com/floors/import/', {
                method: 'POST',
                body: formData,
            });

            if (response.ok) {
                alert('Floors imported successfully');
                setIsImportModalOpen(false);
                setFile(null); // Clear the file input
                setSelectedMallId(""); // Reset mall selection
                fetchFloors(selectedMallId); // Refresh the floors list for the selected mall
            } else {
                throw new Error('Failed to import floors');
            }
        } catch (error) {
            alert(error.message);
        }
    };


    // Close modals when clicking outside
    const closeModal = (e, setModalState) => {
        if (modalRef.current && !modalRef.current.contains(e.target)) {
            setModalState(false);
        }
    };

    // Fetch floors on component mount
    useEffect(() => {
        fetchMalls();
        document.addEventListener("mousedown", (e) => closeModal(e, setIsAddModalOpen));
        document.addEventListener("mousedown", (e) => closeModal(e, setIsEditModalOpen));
        document.addEventListener("mousedown", (e) => closeModal(e, setIsImportModalOpen));
        document.addEventListener("mousedown", (e) => closeModal(e, setIsViewModalOpen));
        return () => {
            document.removeEventListener("mousedown", closeModal);
        };
    }, []);

    return (
        <div className="floor-container">
            <h1 className="floor-title">Floors Management</h1>
            {notification.message && (
                <div className={`floor-notification ${notification.type}`}>{notification.message}</div>
            )}

            {/* Button container */}
            <div className="floor-button-container">
                <button className="floor-action-btn floor-add-btn" onClick={() => setIsAddModalOpen(true)}>Create New
                    Floors
                </button>
                <button className="floor-action-btn floor-import-btn" onClick={() => setIsImportModalOpen(true)}>Import
                    floors
                </button>
                <button className="floor-action-btn floor-export-btn" onClick={exportFloors}>Export floors</button>
            </div>
            <div className="mall-selector">
                <select
                    value={selectedMallId}
                    onChange={(e) => setSelectedMallId(e.target.value)}
                    required
                    className="floor-form-select"
                >
                    <option value="">Select Mall</option>
                    {malls.map((mall) => (
                        <option key={mall._id} value={mall._id}>
                            {mall.name} - {mall.location}
                        </option>
                    ))}
                </select>

                <button
                    className="floor-action-btn floor-view-btn"
                    onClick={() => {
                        const selectedMall = malls.find((mall) => mall._id === selectedMallId);
                        if (selectedMall) {
                            setSelectedMall(selectedMall); // Set selected mall
                            fetchFloors(selectedMallId); // Fetch floors for the selected mall
                        }
                    }}
                    disabled={!selectedMallId} // Disable button until a mall is selected
                >
                    View Floors
                </button>
            </div>


            {/* Floor Table */}
            <div className="floor-table-container">
                {floors && floors.length > 0 ? (
                    <table className="floor-table">
                        <thead>
                        <tr>
                            <th>Mall Name</th>
                            <th>Name</th>
                            <th>Order</th>
                            <th>Actions</th>
                        </tr>
                        </thead>
                        <tbody>
                        {floors.map((floor) => (
                            <tr key={floor._id}>
                                <td>{getMallName(floor.mall_id)}</td>
                                <td>{floor.label}</td>
                                <td>{floor.order}</td>
                                <td>
                                    <button className="floor-action-btn floor-view-btn"
                                            onClick={() => {
                                                setSelectedMall(floor); // Set selected mall
                                                viewFloorDetails(floor._id); // Fetch floors for the selected mall
                                            }}>View Stores
                                    </button>
                                    <button
                                        className="floor-action-btn floor-edit-btn"
                                        onClick={() => handleEditClick(floor)}
                                    >
                                        Edit
                                    </button>

                                    <button className="floor-action-btn floor-delete-btn"
                                            onClick={() => deleteMall(floor._id)}>Delete
                                    </button>
                                </td>
                            </tr>
                        ))}
                        </tbody>
                    </table>
                ) : (
                    <p>No floors data available</p>
                )}
            </div>

            {/* Add, Edit, Import, and View Modals */}
            {/* Implement similar to CustomerManagement with the updated floor-specific classes */}
            {/* Add Mall Modal */}
            {isAddModalOpen && (
                <div className="floor-modal-overlay" onClick={(e) => closeModal(e, setIsAddModalOpen)}>
                    <div className="floor-modal-content" ref={modalRef}>
                        <button className="floor-close-btn" onClick={() => setIsAddModalOpen(false)}><MdClose/></button>
                        <h2 className="floor-modal-title">Add New Floors</h2>
                        <form onSubmit={(e) => {
                            e.preventDefault();
                            addMall();
                        }} className="floor-form-container">
                            <select
                                value={addSelectedMallId}
                                onChange={(e) => setaddSelectedMallId(e.target.value)}
                                required
                                className="floor-form-select"
                            >
                                <option value="">Select Malls</option>
                                {malls.map((mall) => (
                                    <option key={mall._id} value={mall._id}>
                                        {mall.name} - {mall.location}
                                    </option>
                                ))}
                            </select>
                            <input type="text" name="label" placeholder="Floor Name" required
                                   onChange={(e) => setFormData({...formData, label: e.target.value})}/>
                            <input type="text" name="order" placeholder="Order" required
                                   onChange={(e) => setFormData({...formData, order: e.target.value})}/>
                            <button type="submit" className="floor-submit-btn">Add Floor</button>
                        </form>
                    </div>
                </div>
            )}

            {/* Edit Mall Modal */}
            {isEditModalOpen && selectedMall && (
                <div className="floor-modal-overlay" onClick={(e) => closeModal(e, setIsEditModalOpen)}>
                    <div className="floor-modal-content" ref={modalRef}>
                        <button className="floor-close-btn" onClick={() => setIsEditModalOpen(false)}><MdClose/>
                        </button>
                        <h2 className="floor-modal-title">Edit Floor</h2>
                        <form onSubmit={(e) => {
                            e.preventDefault();
                            updateMall();
                        }} className="floor-form-container">

                            <input type="text" name="label" value={formData.label || ''} placeholder="Floor Name"
                                   required
                                   onChange={(e) => setFormData({...formData, label: e.target.value})}/>
                            <input type="text" name="order" value={formData.order || ''} placeholder="Order"
                                   required
                                   onChange={(e) => setFormData({...formData, order: e.target.value})}/>
                            <button type="submit" className="floor-submit-btn">Update Mall</button>
                        </form>
                    </div>
                </div>
            )}

            {/* Import Mall Modal */}
            {isImportModalOpen && (
                <div className="floor-import-modal-overlay" onClick={(e) => closeModal(e, setIsImportModalOpen)}>
                    <div className="floor-import-modal-content" ref={modalRef}>
                        <button className="floor-close-btn" onClick={() => setIsImportModalOpen(false)}><MdClose/>
                        </button>
                        <h2 className="floor-import-modal-title">Import Floors</h2>
                        <form
                            onSubmit={(e) => {
                                e.preventDefault();
                                importFloors();
                            }}
                            className="floor-form-container"
                        >
                            <select
                                value={selectedMallId}
                                onChange={(e) => setSelectedMallId(e.target.value)}
                                required
                                className="floor-form-select"
                            >
                                <option value="">Select Mall</option>
                                {malls.map((mall) => (
                                    <option key={mall._id} value={mall._id}>
                                        {mall.name} - {mall.location}
                                    </option>
                                ))}
                            </select>
                            <input
                                type="file"
                                className="floor-import-file-input"
                                onChange={(e) => setFile(e.target.files[0])}
                                required
                            />
                            <button type="submit" className="floor-import-action-btn">Import</button>
                        </form>
                    </div>
                </div>
            )}


            {/* View Mall Modal */}
            {isViewModalOpen && selectedMall && (
                <div className="floor-view-modal-overlay" onClick={(e) => closeModal(e, setIsViewModalOpen)}>
                    <div className="floor-view-modal-content" ref={modalRef}>
                        <button className="floor-close-btn" onClick={() => setIsViewModalOpen(false)}><MdClose/>
                        </button>
                        <div className="floor-view-container">
                            <h2 className="floor-view-modal-title">Stores Details</h2>

                            {/* Check if floors is available and has data */}
                            {selectedMall.stores && selectedMall.stores.length > 0 ? (
                                <table className="floor-view-details-table">
                                    <thead>
                                    <tr>
                                        <th>Name</th>
                                        <th>Order</th>
                                        <th>open_close_info</th>
                                        <th>address</th>
                                        <th>latitude</th>
                                        <th>longitude</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {selectedMall.stores.map((store) => (
                                        <tr key={store._id}>
                                            <td>{store.name}</td>
                                            <td>{store.description}</td>
                                            <td>{store.open_close_info}</td>
                                            <td>{store.address}</td>
                                            <td>{store.latitude}</td>
                                            <td>{store.longitude}</td>
                                        </tr>
                                    ))}
                                    </tbody>
                                </table>
                            ) : (
                                <p>No Store data available</p>
                            )}

                        </div>
                    </div>
                </div>
            )}


        </div>
    );
};

export default FloorManagement;
