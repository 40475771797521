import React, {useState, useEffect, useRef} from 'react';
import './MediaManagement.css';
import {MdClose} from 'react-icons/md';
import ReactMarkdown from 'react-markdown';

const MediaManagement = () => {
    const [Medias, setMedias] = useState([]);
    const [formData, setFormData] = useState({});
    const [notification, setNotification] = useState({message: '', type: ''});
    const [file, setFile] = useState(null);
    const [selectedMedia, setSelectedMedia] = useState(null);
    const [isAddModalOpen, setIsAddModalOpen] = useState(false);
    const [isEditModalOpen, setIsEditModalOpen] = useState(false);
    const [isImportModalOpen, setIsImportModalOpen] = useState(false);
    const [isViewModalOpen, setIsViewModalOpen] = useState(false);
    const modalRef = useRef();
    const [customers, setCustomers] = useState([]);
    const [selectedCustomerId, setSelectedCustomerId] = useState("");
    const [floors, setFloors] = useState([]);
    const [malls, setMalls] = useState([]);
    const [selectedMallId, setSelectedMallId] = useState("");
    const [selectedStoreId, setselectedStoreId] = useState("");
    const [floorMap, setFloorMap] = useState({});
    const [mallMap, setMallMap] = useState({});
    const [Stores, setStores] = useState([]);


    // Fetch customers on component mount
    useEffect(() => {
        fetchStores();
    }, []);

    // const fetchCustomers = async () => {
    //     try {
    //         const response = await fetch("https://api.finimaps.com/customers/");
    //         const data = await response.json();
    //         setCustomers(data);
    //     } catch (error) {
    //         console.error("Failed to fetch customers", error);
    //     }
    // };

    // Fetch all malls
    const fetchMalls = async () => {
        try {
            const response = await fetch('https://api.finimaps.com/malls/');
            if (response.ok) {
                const data = await response.json();
                setMalls(data);
            } else {
                throw new Error('Failed to load malls');
            }
        } catch (error) {
            setNotification({message: error.message, type: 'error'});
        }
    };

    // Helper function to get customer name by ID
    const getFloorName = (customerId) => {
        const store = Stores.find(c => c._id === customerId);
        return store ? store.name : 'Unknown Floor';
    };

    const [searchTerm, setSearchTerm] = useState("");

    const handleSearchChange = (e) => {
        setSearchTerm(e.target.value.toLowerCase());
    };

    const filteredMedias = Medias.filter(media =>
        media.description.toLowerCase().includes(searchTerm) ||
        media.media_type.toLowerCase().includes(searchTerm) ||
        getFloorName(media.store_id).toLowerCase().includes(searchTerm)
    );


    // Fetch all Stores
    const fetchStores = async () => {
        try {
            const response = await fetch('https://api.finimaps.com/stores/');
            if (response.ok) {
                const data = await response.json();
                setStores(data);
            } else {
                throw new Error('Failed to load Stores');
            }
        } catch (error) {
            setNotification({message: error.message, type: 'error'});
        }
    };


    // Fetch all Medias
    const fetchMedias = async () => {
        try {
            const response = await fetch('https://api.finimaps.com/media/');
            if (response.ok) {
                const data = await response.json();
                setMedias(data);
            } else {
                throw new Error('Failed to load Medias');
            }
        } catch (error) {
            setNotification({message: error.message, type: 'error'});
        }
    };

    // Add a new Media
    const addMedia = async () => {
        try {
            const response = await fetch("https://api.finimaps.com/media/", {
                method: "POST",
                headers: {"Content-Type": "application/json"},
                body: JSON.stringify({
                    store_id: selectedStoreId,  // Floor ID from the selected floor dropdown
                    media_type: formData.media_type,
                    description: formData.description,
                    media_url: formData.media_url,
                }),
            });
            if (response.ok) {
                alert("Media added successfully");
                setFormData({});
                setSelectedMallId("");
                setselectedStoreId("");
                setIsAddModalOpen(false);
                fetchMedias();
            } else {
                throw new Error("Failed to add Media");
            }
        } catch (error) {
            alert(error.message, "error");
        }
    };


    const openEditModal = (Media) => {
        setSelectedMedia(Media);
        setFormData({
            media_type: Media.media_type,
            description: Media.description,
            media_url: Media.media_url,
        });
        setIsEditModalOpen(true);
    };
    // Update a Media by ID
    const updateMedia = async () => {
        try {
            if (!selectedMedia) return;

            const response = await fetch(`https://api.finimaps.com/media/${selectedMedia._id}`, {
                method: "PUT",
                headers: {"Content-Type": "application/json"},
                body: JSON.stringify({
                    media_type: formData.media_type,
                    description: formData.description,
                    media_url: formData.media_url,
                }),
            });
            if (response.ok) {
                alert("Media updated successfully");
                setIsEditModalOpen(false);
                fetchMedias();  // Refresh Medias list after updating
            } else {
                throw new Error("Failed to update Media");
            }
        } catch (error) {
            alert(error.message);
        }
    };


    // Delete a Media by ID
    const deleteMedia = async (id) => {
        const confirmed = window.confirm("Are you sure you want to delete this Media?");
        if (!confirmed) return;

        try {
            const response = await fetch(`https://api.finimaps.com/media/${id}`, {method: 'DELETE'});
            if (response.ok) {
                alert('Media deleted successfully');
                setMedias(Medias.filter(Media => Media._id !== id));
            } else {
                throw new Error('Failed to delete Media');
            }
        } catch (error) {
            alert(error.message);
        }
    };

    // Export Medias to CSV
    const exportMedias = async () => {
        try {
            const response = await fetch('https://api.finimaps.com/media/export/');
            if (response.ok) {
                const blob = await response.blob();
                const url = URL.createObjectURL(blob);
                const link = document.createElement('a');
                link.href = url;
                link.download = 'Medias.csv';
                link.click();
                alert('Medias exported successfully');
            } else {
                throw new Error('Failed to export Medias');
            }
        } catch (error) {
            alert(error.message);
        }
    };

    // Import Medias from CSV
    // Import Medias from CSV
    const importMedias = async () => {
        if (!file || !selectedStoreId) {
            alert('Please select a store and upload a file before importing.');
            return;
        }

        const formData = new FormData();
        formData.append('file', file); // Attach the file
        formData.append('store_id', selectedStoreId); // Attach the store ID
        try {
            const response = await fetch('https://api.finimaps.com/media/import/', {
                method: 'POST',
                body: formData, // Send FormData directly as the body
            });
            if (response.ok) {
                alert('Medias imported successfully');
                setIsImportModalOpen(false);
                setFile(null); // Clear the file input after successful import
                setselectedStoreId(""); // Clear the selected store ID
                fetchMedias(); // Refresh the Medias list
            } else {
                const errorData = await response.json();
                throw new Error(errorData.detail);
            }
        } catch (error) {
            alert(`Error: ${error.message}`);
        }
    };


    // Fetch Media details for viewing
    // const viewMediaDetail = async (id) => {
    //     try {
    //         const response = await fetch(`https://api.finimaps.com/media/${id}/detail/`);
    //         if (response.ok) {
    //             const data = await response.json();
    //             setSelectedMedia(data);
    //             setIsViewModalOpen(true);
    //         } else {
    //             throw new Error('Failed to fetch Media details');
    //         }
    //     } catch (error) {
    //         setNotification({message: error.message, type: 'error'});
    //     }
    // };

    // Close modals when clicking outside
    const closeModal = (e, setModalState) => {
        if (modalRef.current && !modalRef.current.contains(e.target)) {
            setModalState(false);
        }
    };

    // Add this function or update if it exists
    const viewMediaDetail = (media) => {
        setSelectedMedia(media);
        setIsViewModalOpen(true);
    };


    // Fetch Medias on component mount
    useEffect(() => {
        fetchMedias();
        document.addEventListener("mousedown", (e) => closeModal(e, setIsAddModalOpen));
        document.addEventListener("mousedown", (e) => closeModal(e, setIsEditModalOpen));
        document.addEventListener("mousedown", (e) => closeModal(e, setIsImportModalOpen));
        document.addEventListener("mousedown", (e) => closeModal(e, setIsViewModalOpen));
        return () => {
            document.removeEventListener("mousedown", closeModal);
        };
    }, []);

    return (
        <div className="Media-container">
            <h1 className="Media-title">Media Management</h1>
            <div className="media-search-container">
                <input
                    type="text"
                    placeholder="Search by Store Name, Media Type, or Description..."
                    className="media-search-input"
                    value={searchTerm}
                    onChange={handleSearchChange}
                />
            </div>

            {/* Button container */}
            <div className="Media-button-container">
                <button className="Media-action-btn Media-add-btn" onClick={() => setIsAddModalOpen(true)}>Create New
                    Media
                </button>
                <button className="Media-action-btn Media-import-btn" onClick={() => setIsImportModalOpen(true)}>Import
                    Media
                </button>
                <button className="Media-action-btn Media-export-btn" onClick={exportMedias}>Export Media</button>
            </div>

            {/* Media Table */}
            <div className="Media-table-container">
                <table className="Media-table">
                    <thead>
                    <tr>
                        <th>Store name</th>
                        <th>Media Type</th>
                        <th>Description</th>
                        <th>Actions</th>
                    </tr>
                    </thead>
                    <tbody>
                    {filteredMedias.map((Media) => (
                        <tr key={Media._id}>
                            <td>{getFloorName(Media.store_id)}</td>
                            <td>{Media.media_type}</td>
                            <td>{Media.description}</td>
                            <td>
                                <button className="Media-action-btn Media-view-btn"
                                        onClick={() => viewMediaDetail(Media)}>
                                    View Media
                                </button>
                                <button className="Media-action-btn Media-edit-btn"
                                        onClick={() => openEditModal(Media)}>
                                    Edit
                                </button>
                                <button className="Media-action-btn Media-delete-btn"
                                        onClick={() => deleteMedia(Media._id)}>
                                    Delete
                                </button>
                            </td>
                        </tr>
                    ))}
                    </tbody>

                </table>
            </div>

            {/* Add, Edit, Import, and View Modals */}
            {/* Implement similar to CustomerManagement with the updated Media-specific classes */}
            {/* Add Media Modal */}
            {isAddModalOpen && (
                <div className="Media-modal-overlay" onClick={(e) => closeModal(e, setIsAddModalOpen)}>
                    <div className="Media-modal-content" ref={modalRef}>
                        <button className="Media-close-btn" onClick={() => setIsAddModalOpen(false)}>
                            <MdClose/>
                        </button>
                        <h2 className="Media-modal-title">Add New Media</h2>
                        <form
                            onSubmit={(e) => {
                                e.preventDefault();
                                addMedia();
                            }}
                            className="Media-form-container"
                        >
                            <select
                                value={selectedStoreId}
                                onChange={(e) => setselectedStoreId(e.target.value)}
                                required
                                className="mall-form-select"
                            >
                                <option value="">Select Store</option>
                                {Stores.map((store) => (
                                    <option key={store._id} value={store._id}>
                                        {store.name}
                                    </option>
                                ))}
                            </select>

                            {/* Media Name */}
                            <select
                                name="media_type"
                                required
                                value={formData.media_type || ""}
                                onChange={(e) => setFormData({...formData, media_type: e.target.value})}
                                className="mall-form-select"
                            >
                                <option value="">Select Media Type</option>
                                <option value="Image">Image</option>
                                <option value="Video">Video</option>
                            </select>


                            {/* Open Close Info */}
                            <input
                                type="text"  // Change this to "text" as it's a string field, not a number
                                name="description"
                                placeholder="Description"
                                required
                                onChange={(e) => setFormData({...formData, description: e.target.value})}
                            />

                            {/* Address */}
                            <input
                                type="text"  // Change this to "text" as it's a string field
                                name="media_url"
                                placeholder="URL"
                                required
                                onChange={(e) => setFormData({...formData, media_url: e.target.value})}
                            />

                            <button type="submit" className="Media-submit-btn">Add Media</button>
                        </form>
                    </div>
                </div>
            )}

            {/* Edit Media Modal */}
            {isEditModalOpen && selectedMedia && (
                <div className="Media-modal-overlay" onClick={(e) => closeModal(e, setIsEditModalOpen)}>
                    <div className="Media-modal-content" ref={modalRef}>
                        <button className="Media-close-btn" onClick={() => setIsEditModalOpen(false)}>
                            <MdClose/>
                        </button>
                        <h2 className="Media-modal-title">Edit Media</h2>
                        <form
                            onSubmit={(e) => {
                                e.preventDefault();
                                updateMedia();
                            }}
                            className="Media-form-container"
                        >
                            {/* Media Name */}

                            <select
                                name="media_type"
                                value={formData.media_type || ""}
                                required
                                onChange={(e) => setFormData({...formData, media_type: e.target.value})}
                                className="mall-form-select"
                            >
                                <option value="" disabled>Select Media Type</option>
                                <option value="Image">Image</option>
                                <option value="Video">Video</option>
                            </select>


                            {/* Open Close Info */}
                            <input
                                type="text"  // Change this to "text" as it's a string field, not a number
                                name="description"
                                value={formData.description || ''}
                                placeholder="Description"
                                required
                                onChange={(e) => setFormData({...formData, description: e.target.value})}
                            />

                            {/* Address */}
                            <input
                                type="text"  // Change this to "text" as it's a string field
                                name="media_url"
                                value={formData.media_url || ''}
                                placeholder="URL"
                                required
                                onChange={(e) => setFormData({...formData, media_url: e.target.value})}
                            />

                            <button type="submit" className="Media-submit-btn">Update Media</button>
                        </form>
                    </div>
                </div>
            )}

            {/* Import Media Modal */}
            {isImportModalOpen && (
                <div className="Media-import-modal-overlay" onClick={(e) => closeModal(e, setIsImportModalOpen)}>
                    <div className="Media-import-modal-content" ref={modalRef}>
                    <button className="Media-close-btn" onClick={() => setIsImportModalOpen(false)}><MdClose/>
                        </button>
                        <h2 className="Media-import-modal-title">Import Media</h2>
                        <select
                            value={selectedStoreId}
                            onChange={(e) => setselectedStoreId(e.target.value)}
                            required
                            className="mall-form-select"
                        >
                            <option value="">Select Store</option>
                            {Stores.map((store) => (
                                <option key={store._id} value={store._id}>
                                    {store.name}
                                </option>
                            ))}
                        </select>
                        <input type="file" className="Media-import-file-input"
                               onChange={(e) => setFile(e.target.files[0])}/>
                        <button onClick={importMedias} className="Media-import-action-btn">Import</button>
                    </div>
                </div>
            )}

            {/* View Media Modal */}
            {/* View Media Modal */}
            {isViewModalOpen && selectedMedia && (
                <div className="Media-view-modal-overlay" onClick={(e) => closeModal(e, setIsViewModalOpen)}>
                    <div className="Media-view-modal-content" ref={modalRef}>
                        <button className="Media-close-btn" onClick={() => setIsViewModalOpen(false)}>
                            <MdClose/>
                        </button>
                        <h2 className="Media-view-modal-title">Media Details</h2>
                        <div className="Medias-floor-container">
                            {Medias && Medias.length > 0 ? (
                                <table className="floor-Medias-table">
                                    <thead>
                                    <tr>
                                        <th>Media</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {Medias.map((Media) => (
                                        <tr key={Media._id}>
                                            <td>
                                                {Media.media_url.match(/\.(jpeg|jpg|gif|png)$/i) ? (
                                                    <img
                                                        src={Media.media_url}
                                                        alt="Media"
                                                        style={{maxWidth: '100px', maxHeight: '100px'}}
                                                    />
                                                ) : (
                                                    <a href={Media.media_url} target="_blank" rel="noopener noreferrer">
                                                        {Media.media_url}
                                                    </a>
                                                )}
                                            </td>
                                        </tr>
                                    ))}
                                    </tbody>
                                </table>
                            ) : (
                                <p className="no-Medias-message">No Media yet</p>
                            )}
                        </div>
                    </div>
                </div>
            )}


        </div>
    );
};

export default MediaManagement;
