import { useNavigate } from 'react-router-dom';
import './Login.css';
import loginImage from '../Assets/login.jpg';
// import { Link } from "react-router-dom";
import React, { useState, useEffect } from 'react';
import {FaEye, FaEyeSlash} from 'react-icons/fa';



function Login() {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const navigate = useNavigate();
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [passwordShown, setPasswordShown] = useState(false);  // State for showing/hiding password


    const togglePasswordVisibility = () => {
        setPasswordShown(!passwordShown);
    };

    useEffect(() => {
        if (isLoggedIn) {
            navigate('/Dashboard');
        }
    }, [isLoggedIn, navigate]);


    // Modify handleSubmit to call initializeChatbot after a successful login
    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true);
        try {
            const response = await fetch('https://api.finimaps.com/login/admin', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
                body: new URLSearchParams({
                    'username': email,
                    'password': password
                })
            });

            if (!response.ok) {
                setIsLoading(false);
                throw new Error('Incorrect username or password');
            }

            const data = await response.json();
            localStorage.setItem('accessToken', data.access_token);

            // Call initializeChatbot here, make sure to pass navigate as an argument
            // await initializeChatbot(navigate);
            setIsLoading(false);

            setIsLoggedIn(true); // Move this inside initializeChatbot after successful initialization
            //alert("Login Successful");

        } catch (error) {
            setErrorMessage("Login Failed: " + error.message);
        }
    };

    const initializeChatbot = async (navigate) => {
        try {
            const accessToken = localStorage.getItem('accessToken');

            if (!accessToken) {
                throw new Error("No access token found. Please login or sign up.");
            }

        
            const initResponse = await fetch('https://api.finimaps.com/initialize_model/', {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${accessToken}`,
                },
                // Assuming you eventually want to send the formData
                // body: formData,
            });

            if (!initResponse.ok) {
                if (initResponse.statusText === "Unauthorized") {
                    localStorage.removeItem('accessToken');
                    navigate('/login');
                    throw new Error(`Session Expired. Login again`);
                } else {
                    throw new Error(`Failed to initialize the model: ${initResponse.statusText}`);
                }
            }
        
        } catch (error) {
            console.error('Error initializing the chatbot:', error.message);
            alert(`Chatbot initialization failed: ${error.message}`);
        }
    };


    return (
        <div className="login-container">
            <div className="login-card">
                <div className="login-image-container">
                    <img src={loginImage} alt="Login" className="login-image" />
                </div>
                <form className="login-form" onSubmit={handleSubmit}>
                    <h1>Welcome to Finimaps </h1>
                    <input
                        type="email"
                        placeholder="Email"
                        required
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                    />
                    <div className="password-field">
                        <input
                            type={passwordShown ? 'text' : 'password'}
                            placeholder="Password"
                            required
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                        />
                        <i onClick={togglePasswordVisibility}
                           style={{position: 'absolute', right: '15px', top: '49%', transform: 'translateY(-50%)'}}>
                            {passwordShown ? <FaEye/> : <FaEyeSlash/>}
                        </i>
                    </div>
                        {/* <button type="submit">Login</button> */}
                        <div className="signup-action-container">
                            <button type="submit" className="signup-btn" disabled={isLoading}>Login</button>
                            {isLoading && <div className="loader"></div>}
                        </div>
                        {errorMessage && <div className="error-message">{errorMessage}</div>}
                        {/* <p className="signup-message">
                        Don't have an account? <Link to="/signup">Sign up</Link>
                    </p> */}
                </form>
            </div>
        </div>
    );
}

export default Login;
