import React, {useState, useEffect, useRef} from 'react';
import './MallManagement.css';
import {MdClose} from 'react-icons/md';

const MallManagement = () => {
    const [malls, setMalls] = useState([]);
    const [formData, setFormData] = useState({});
    const [notification, setNotification] = useState({message: '', type: ''});
    const [file, setFile] = useState(null);
    const [selectedMall, setSelectedMall] = useState(null);
    const [isAddModalOpen, setIsAddModalOpen] = useState(false);
    const [isEditModalOpen, setIsEditModalOpen] = useState(false);
    const [isImportModalOpen, setIsImportModalOpen] = useState(false);
    const [isViewModalOpen, setIsViewModalOpen] = useState(false);
    const modalRef = useRef();
    const [customers, setCustomers] = useState([]);
    const [selectedCustomerId, setSelectedCustomerId] = useState("");

    // Fetch customers on component mount
    useEffect(() => {
        fetchCustomers();
    }, []);

    const fetchCustomers = async () => {
        try {
            const response = await fetch("https://api.finimaps.com/customers/");
            const data = await response.json();
            setCustomers(data);
        } catch (error) {
            console.error("Failed to fetch customers", error);
        }
    };

    // Helper function to get customer name by ID
    const getCustomerName = (customerId) => {
        const customer = customers.find(c => c._id === customerId);
        return customer ? customer.name : 'Unknown Customer';
    };

    // Fetch all malls
    const fetchMalls = async () => {
        try {
            const response = await fetch('https://api.finimaps.com/malls/');
            if (response.ok) {
                const data = await response.json();
                setMalls(data);
            } else {
                throw new Error('Failed to load malls');
            }
        } catch (error) {
            setNotification({message: error.message, type: 'error'});
        }
    };

    // Add a new mall
    const addMall = async () => {
        try {
            const response = await fetch("https://api.finimaps.com/malls/", {
                method: "POST",
                headers: {"Content-Type": "application/json"},
                body: JSON.stringify({
                    ...formData,
                    customer_id: selectedCustomerId,
                }),
            });
            if (response.ok) {
                alert("Mall added successfully");
                setFormData({});
                setSelectedCustomerId("");
                setIsAddModalOpen(false);
                fetchMalls();
            } else {
                throw new Error("Failed to add mall");
            }
        } catch (error) {
            alert(error.message, "error");
        }
    };

    const [searchTerm, setSearchTerm] = useState("");

    const handleSearchChange = (e) => {
        setSearchTerm(e.target.value.toLowerCase());
    };

    const filteredMalls = malls.filter(mall =>
        mall.name.toLowerCase().includes(searchTerm) ||
        mall.location.toLowerCase().includes(searchTerm) ||
        getCustomerName(mall.customer_id).toLowerCase().includes(searchTerm)
    );


    const openEditModal = (mall) => {
        setSelectedMall(mall);
        setFormData({
            name: mall.name,
            location: mall.location,
            total_area_sqft: mall.total_area_sqft,
            parking_capacity: mall.parking_capacity,
            opening_date: mall.opening_date.split("T")[0], // Adjust date format for input
        });
        setIsEditModalOpen(true);
    };
    // Update a mall by ID
    // Update a mall by ID
    const updateMall = async () => {
        try {
            if (!selectedMall) return;

            const response = await fetch(`https://api.finimaps.com/malls/${selectedMall._id}`, {
                method: "PUT",
                headers: {"Content-Type": "application/json"},
                body: JSON.stringify({
                    name: formData.name,
                    location: formData.location,
                    total_area_sqft: formData.total_area_sqft,
                    parking_capacity: formData.parking_capacity,
                    opening_date: new Date(formData.opening_date).toISOString(),
                }),
            });
            if (response.ok) {
                alert("Mall updated successfully");
                setIsEditModalOpen(false);
                fetchMalls();
            } else {
                throw new Error("Failed to update mall");
            }
        } catch (error) {
            alert(error.message);
        }
    };

    // Delete a mall by ID
    const deleteMall = async (id) => {
        const confirmed = window.confirm("Are you sure you want to delete this mall?");
        if (!confirmed) return;

        try {
            const response = await fetch(`https://api.finimaps.com/malls/${id}`, {method: 'DELETE'});
            if (response.ok) {
                alert('Mall deleted successfully');
                setMalls(malls.filter(mall => mall._id !== id));
            } else {
                throw new Error('Failed to delete mall');
            }
        } catch (error) {
            alert(error.message);
        }
    };

    // Export malls to CSV
    const exportMalls = async () => {
        try {
            const response = await fetch('https://api.finimaps.com/malls/export/');
            if (response.ok) {
                const blob = await response.blob();
                const url = URL.createObjectURL(blob);
                const link = document.createElement('a');
                link.href = url;
                link.download = 'malls.csv';
                link.click();
                alert('Malls exported successfully');
            } else {
                throw new Error('Failed to export malls');
            }
        } catch (error) {
            alert(error.message);
        }
    };

    // Import malls from CSV
    const importMalls = async () => {
        const formData = new FormData();
        formData.append('file', file);
        formData.append('customer_id', selectedCustomerId);
        try {
            const response = await fetch('https://api.finimaps.com/malls/import/', {
                method: 'POST',
                body: formData,
            });
            if (response.ok) {
                alert('Malls imported successfully');
                setIsImportModalOpen(false);
                fetchMalls();
            } else {
                throw new Error('Failed to import malls');
            }
        } catch (error) {
            alert(error.message);
        }
    };

    // Fetch mall details for viewing
    const viewMallDetail = async (id) => {
        try {
            const response = await fetch(`https://api.finimaps.com/malls/${id}/detail/`);
            if (response.ok) {
                const data = await response.json();
                setSelectedMall(data);
                setIsViewModalOpen(true);
            } else {
                throw new Error('Failed to fetch mall details');
            }
        } catch (error) {
            setNotification({message: error.message, type: 'error'});
        }
    };

    // Close modals when clicking outside
    const closeModal = (e, setModalState) => {
        if (modalRef.current && !modalRef.current.contains(e.target)) {
            setModalState(false);
        }
    };

    // Fetch malls on component mount
    useEffect(() => {
        fetchMalls();
        document.addEventListener("mousedown", (e) => closeModal(e, setIsAddModalOpen));
        document.addEventListener("mousedown", (e) => closeModal(e, setIsEditModalOpen));
        document.addEventListener("mousedown", (e) => closeModal(e, setIsImportModalOpen));
        document.addEventListener("mousedown", (e) => closeModal(e, setIsViewModalOpen));
        return () => {
            document.removeEventListener("mousedown", closeModal);
        };
    }, []);

    return (
        <div className="mall-container">
            <h1 className="mall-title">Mall Management</h1>
            {notification.message && (
                <div className={`mall-notification ${notification.type}`}>{notification.message}</div>
            )}

            <div className="mall-search-container">
                <input
                    type="text"
                    placeholder="Search by Mall Name, Location, or Customer Name..."
                    className="mall-search-input"
                    value={searchTerm}
                    onChange={handleSearchChange}
                />
            </div>


            {/* Button container */}
            <div className="mall-button-container">
                <button className="mall-action-btn mall-add-btn" onClick={() => setIsAddModalOpen(true)}>Create New Mall
                </button>
                <button className="mall-action-btn mall-import-btn" onClick={() => setIsImportModalOpen(true)}>Import
                    Malls
                </button>
                <button className="mall-action-btn mall-export-btn" onClick={exportMalls}>Export Malls</button>
            </div>

            {/* Mall Table */}
            <div className="mall-table-container">
                <table className="mall-table">
                    <thead>
                    <tr>
                        <th>Customer Name</th>
                        <th>Name</th>
                        <th>Location</th>
                        <th>Total Area (sqft)</th>
                        <th>Parking Capacity</th>
                        <th>Opening Date</th>
                        <th>Actions</th>
                    </tr>
                    </thead>
                    <tbody>
                    {filteredMalls.map((mall) => (
                        <tr key={mall._id}>
                            <td>{getCustomerName(mall.customer_id)}</td>
                            <td>{mall.name}</td>
                            <td>{mall.location}</td>
                            <td>{mall.total_area_sqft}</td>
                            <td>{mall.parking_capacity}</td>
                            <td>{mall.opening_date}</td>
                            <td>
                                <button className="mall-action-btn mall-view-btn"
                                        onClick={() => viewMallDetail(mall._id)}>
                                    View Floors
                                </button>
                                <button className="mall-action-btn mall-edit-btn" onClick={() => openEditModal(mall)}>
                                    Edit
                                </button>
                                <button className="mall-action-btn mall-delete-btn"
                                        onClick={() => deleteMall(mall._id)}>
                                    Delete
                                </button>
                            </td>
                        </tr>
                    ))}
                    </tbody>

                </table>
            </div>

            {/* Add, Edit, Import, and View Modals */}
            {/* Implement similar to CustomerManagement with the updated mall-specific classes */}
            {/* Add Mall Modal */}
            {isAddModalOpen && (
                <div className="mall-modal-overlay" onClick={(e) => closeModal(e, setIsAddModalOpen)}>
                    <div className="mall-modal-content" ref={modalRef}>
                        <button className="mall-close-btn" onClick={() => setIsAddModalOpen(false)}>
                            <MdClose/>
                        </button>
                        <h2 className="mall-modal-title">Add New Mall</h2>
                        <form
                            onSubmit={(e) => {
                                e.preventDefault();
                                addMall();
                            }}
                            className="mall-form-container"
                        >
                            <select
                                value={selectedCustomerId}
                                onChange={(e) => setSelectedCustomerId(e.target.value)}
                                required
                                className="mall-form-select"
                            >
                                <option value="">Select Customer</option>
                                {customers.map((customer) => (
                                    <option key={customer._id} value={customer._id}>
                                        {customer.name} - {customer.contact_person}
                                    </option>
                                ))}
                            </select>
                            <input
                                type="text"
                                name="name"
                                placeholder="Mall Name"
                                required
                                onChange={(e) => setFormData({...formData, name: e.target.value})}
                            />
                            <input
                                type="text"
                                name="location"
                                placeholder="Location"
                                required
                                onChange={(e) => setFormData({...formData, location: e.target.value})}
                            />
                            <input
                                type="number"
                                name="total_area_sqft"
                                placeholder="Total Area (sqft)"
                                required
                                onChange={(e) => setFormData({...formData, total_area_sqft: e.target.value})}
                            />
                            <input
                                type="number"
                                name="parking_capacity"
                                placeholder="Parking Capacity"
                                required
                                onChange={(e) => setFormData({...formData, parking_capacity: e.target.value})}
                            />
                            <input
                                type="date"
                                name="opening_date"
                                required
                                onChange={(e) => setFormData({...formData, opening_date: e.target.value})}
                            />
                            <button type="submit" className="mall-submit-btn">Add Mall</button>
                        </form>
                    </div>
                </div>
            )}

            {/* Edit Mall Modal */}
            {isEditModalOpen && selectedMall && (
                <div className="mall-modal-overlay" onClick={(e) => closeModal(e, setIsEditModalOpen)}>
                    <div className="mall-modal-content" ref={modalRef}>
                        <button className="mall-close-btn" onClick={() => setIsEditModalOpen(false)}>
                            <MdClose/>
                        </button>
                        <h2 className="mall-modal-title">Edit Mall</h2>
                        <form
                            onSubmit={(e) => {
                                e.preventDefault();
                                updateMall();
                            }}
                            className="mall-form-container"
                        >
                            <input
                                type="text"
                                name="name"
                                value={formData.name || ""}
                                placeholder="Mall Name"
                                required
                                onChange={(e) => setFormData({...formData, name: e.target.value})}
                            />
                            <input
                                type="text"
                                name="location"
                                value={formData.location || ""}
                                placeholder="Location"
                                required
                                onChange={(e) => setFormData({...formData, location: e.target.value})}
                            />
                            <input
                                type="number"
                                name="total_area_sqft"
                                value={formData.total_area_sqft || ""}
                                placeholder="Total Area (sqft)"
                                required
                                onChange={(e) => setFormData({...formData, total_area_sqft: e.target.value})}
                            />
                            <input
                                type="number"
                                name="parking_capacity"
                                value={formData.parking_capacity || ""}
                                placeholder="Parking Capacity"
                                required
                                onChange={(e) => setFormData({...formData, parking_capacity: e.target.value})}
                            />
                            <input
                                type="date"
                                name="opening_date"
                                value={formData.opening_date || ""}
                                required
                                onChange={(e) => setFormData({...formData, opening_date: e.target.value})}
                            />
                            <button type="submit" className="mall-submit-btn">Update Mall</button>
                        </form>
                    </div>
                </div>
            )}

            {/* Import Mall Modal */}
            {isImportModalOpen && (
                <div className="mall-import-modal-overlay" onClick={(e) => closeModal(e, setIsImportModalOpen)}>
                    <div className="mall-import-modal-content" ref={modalRef}>
                        <button className="mall-close-btn" onClick={() => setIsImportModalOpen(false)}><MdClose/>
                        </button>
                        <h2 className="mall-import-modal-title">Import Malls</h2>
                        <select
                            value={selectedCustomerId}
                            onChange={(e) => setSelectedCustomerId(e.target.value)}
                            required
                            className="mall-form-select"
                        >
                            <option value="">Select Customer</option>
                            {customers.map((customer) => (
                                <option key={customer._id} value={customer._id}>
                                    {customer.name} - {customer.contact_person}
                                </option>
                            ))}
                        </select>
                        <input type="file" className="mall-import-file-input"
                               onChange={(e) => setFile(e.target.files[0])}/>
                        <button onClick={importMalls} className="mall-import-action-btn">Import</button>
                    </div>
                </div>
            )}

            {/* View Mall Modal */}
            {isViewModalOpen && selectedMall && (
                <div className="mall-view-modal-overlay" onClick={(e) => closeModal(e, setIsViewModalOpen)}>
                    <div className="mall-view-modal-content" ref={modalRef}>
                        <button className="mall-close-btn" onClick={() => setIsViewModalOpen(false)}><MdClose/></button>
                        {/*<h2 className="mall-view-modal-title">Mall Details</h2>*/}
                        {/*<div className="mall-view-details-container">*/}
                        {/*    <div className="mall-view-detail-row">*/}
                        {/*        <span className="mall-view-detail-label">Name</span>*/}
                        {/*        <span className="mall-view-detail-data">{selectedMall.name}</span>*/}
                        {/*    </div>*/}
                        {/*    <div className="mall-view-detail-row">*/}
                        {/*        <span className="mall-view-detail-label">Location</span>*/}
                        {/*        <span className="mall-view-detail-data">{selectedMall.location}</span>*/}
                        {/*    </div>*/}
                        {/*    <div className="mall-view-detail-row">*/}
                        {/*        <span className="mall-view-detail-label">Total Area (sqft)</span>*/}
                        {/*        <span className="mall-view-detail-data">{selectedMall.total_area_sqft}</span>*/}
                        {/*    </div>*/}
                        {/*    <div className="mall-view-detail-row">*/}
                        {/*        <span className="mall-view-detail-label">Parking Capacity</span>*/}
                        {/*        <span className="mall-view-detail-data">{selectedMall.parking_capacity}</span>*/}
                        {/*    </div>*/}
                        {/*    <div className="mall-view-detail-row">*/}
                        {/*        <span className="mall-view-detail-label">Opening Date</span>*/}
                        {/*        <span className="mall-view-detail-data">{selectedMall.opening_date}</span>*/}
                        {/*    </div>*/}
                        {/*</div>*/}
                        {/* Malls Details Table */}
                        <div className="malls-floor-container">
                            <h3 className="malls-floor-view-modal-subtitle">Floors</h3>
                            {selectedMall.floors && selectedMall.floors.length > 0 ? (
                                <table className="floor-malls-table">
                                    <thead>
                                    <tr>
                                        <th>Name</th>
                                        <th>Order</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {selectedMall.floors.map((floor) => (
                                        <tr key={floor._id}>
                                            <td>{floor.label}</td>
                                            <td>{floor.order}</td>
                                        </tr>
                                    ))}
                                    </tbody>
                                </table>
                            ) : (
                                <p className="no-malls-message">No Floors yet</p>
                            )}
                        </div>
                    </div>
                </div>
            )}

        </div>
    );
};

export default MallManagement;
